import { Chip } from "primereact/chip";
import BreadCrumb from "../shared/BreadCrumb";
import { useContext, useEffect, useRef, useState } from "react";
import therest from "../../therest";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AuthContext } from "../../AuthContext";
import Modals from "../modals/Modals";
import { Button } from "primereact/button";
import { UserContext } from "../../UserContext";
import { Toast } from "primereact/toast";
import { TabPanel, TabView } from "primereact/tabview";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { LoadingContext } from "../../contexts/LoadingContext";
import { BsInfoCircle } from "react-icons/bs";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Steps } from "primereact/steps";
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";

const Earnings = () => { 
  const { apiToken } = useContext(AuthContext);
  const { user } = useContext(UserContext);
  const [totalMember, setTotalMember] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0.00);
  const [totalWithdrawal, setTotalWithdrawal] = useState(0.00);
  const [accumulatedPoints, setAccumulatedPoints] = useState(0.00);
  const [withdrawablepoint, setWithdrawablePoint] = useState(0.00);
  const [earnings, setEarnings] = useState();
  const toast = useRef(null);
  const { setIsShowLoading } = useContext(LoadingContext);

  const getEarnings = () => {
    therest.get('/transactions/earnings', apiToken).then((response) => {
      console.log(response);
      setEarnings(response.earnings);
      setTotalWithdrawal(response.total_winthdrawal);
      setWithdrawablePoint(formatNumber(response.total_withdrawable));
      setTotalEarnings(formatNumber(response.total_earnings));
      setAccumulatedPoints(formatNumber(response.cleared));
      // console.log(withdrawablepoint>5000);
    });
  }

  const getTotalMember=(rowData)=>{
    // console.log(rowData)
    therest.post('/childcount', rowData,apiToken).then((response) => {
      // console.log(response)
      setTotalMember(response.usercount);
    });
  }
  const formatDateTime = (rowData) => {
    return moment(rowData.created_at).format('MMMM DD, YYYY hh:mm A');
  }

  const formatRate = (rowData) => {
    return (rowData.commission_rate * 100) + '%'
  }

  const formatNumber = (rowData) => {
    console.log("Amount is" + rowData.amount);
    // return Number(rowData.amount || rowData).toFixed(2);
    try{
      if(Number(rowData.amount).toFixed(2)===undefined||isNaN(Number(rowData.amount).toFixed(2))){
          return Number(rowData).toFixed(2)
      }else{
          return Number(rowData.amount).toFixed(2)
      }
    }catch{
      console.log(rowData);
      return Number(rowData).toFixed(2);
    }
    
    
  }
  const renderStatus =(rowData)=>{
    switch(rowData.cleared){
      case 1:
        return "Complete";
        break;
      case 0:
        return "Pending";
        break;
      case 5:
        return "Cancelled";
        break;
      case 2:
        return 'In Progress';
      case 3:
        return 'Processing';
      case 4:
        return 'Released';
      default:
        return "Pending";
        
    }
  }

  const renderWithdrawableStatus = (rowData) => {
    switch (rowData.withdrawable) {
      case 2:
        return 'In Progress';
      case 3:
        return 'Processing';
      case 4:
        return 'Released';
      default:
        return "Cancelled";
    }
  }

  const data = {
    points_to_withdraw: 0,
    withdrawal_method: 1,
    otp: '',
  }

  const [withdrawData, setWithdrawData] = useState(data);
  const [viewWithdrawModal, setViewWithdrawModal] = useState(false); // Withdrawal (Inprogress)
  const viewWithdrawModalOnHide = () => {
    setViewWithdrawModal(false);
    setWithdrawData(data);
  }

  const handleWithdrawSubmit = () => {
    // console.log("withdrawData", withdrawData);
    setIsShowLoading(true);
    // validation
    // if(withdrawData.points_to_withdraw < 5000){
    //   toast.current.show({ severity: 'error', summary: 'Failed!', detail: 'Points to withdraw should not be below minimum.' });
    //   return ;
    // }

    if(withdrawData.withdrawal_method == ''){
      toast.current.show({ severity: 'error', summary: 'Failed!', detail: 'Must have set a withdrawal account' });
      return ;
    }

    // verify otp
    therest.post('/otp/verify', {code: withdrawData.otp}, apiToken).then((response) => {
      if(response.status){

        // call withdraw data
        therest.post('/transaction/withdrawal-requests', withdrawData, apiToken).then((withdarawal_response) => {
          if(withdarawal_response.status){
            toast.current.show({ severity: 'success', summary: 'Success!', detail: withdarawal_response.message })
            viewWithdrawModalOnHide();
            getWithdrawalRequests();
            setWithdrawablePoint(formatNumber(withdrawablepoint-withdrawData.points_to_withdraw));
            setIsShowLoading(false);
          } else {
            toast.current.show({ severity: 'error', summary: 'Failed!', detail: withdarawal_response.message });
            setIsShowLoading(false);
          }
        });

      } else {
        toast.current.show({ severity: 'error', summary: 'Failed!', detail: response.message });
        setIsShowLoading(false);
      }
    
    });

  }

  const [activeAccounts, setActiveAccounts] = useState();
  const getActiveWithdrawalAccounts = () => {
   
    therest.get('/active-withdrawal-accounts/' + user.id, apiToken).then((response) => {
      console.table(response);
      if(response.status){
        const updatedAccounts = [...response.accounts, {code: 1, name: 'Cash Pickup'}];
        setActiveAccounts(updatedAccounts);
      }
    });
  }

  useEffect(() => {
    getEarnings();
    getActiveWithdrawalAccounts();
    getTotalMember(user);
  }, []);

  const dt = useRef(null); 
  const dtW = useRef(null); 

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const exportCSVWR = (selectionOnly) => {
    dtW.current.exportCSV({ selectionOnly });
  };

  const [earningsFilters, setEarningsFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });

  const [earningsGlobalFilterValue, setEarningsGlobalFilterValue] = useState('');
  const onEarningsGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...earningsFilters };

    _filters['global'].value = value;

    setEarningsFilters(_filters);
    setEarningsGlobalFilterValue(value);
  };

  const [wrFilters, setWRFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });

  const [wrGlobalFilterValue, seWRGlobalFilterValue] = useState('');
  const onWRGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...wrFilters };

    _filters['global'].value = value;

    setWRFilters(_filters);
    seWRGlobalFilterValue(value);
  };

  const header = (
    <div className="flex justify-between -mx-4">
      <span className="p-input-icon-left flex items-center">
        <i className="pi pi-search" />
        <InputText className="w-full ring-0 border rounded-full pl-10 pr-4 py-3 font-normal" value={earningsGlobalFilterValue} onChange={onEarningsGlobalFilterChange} placeholder="Search" />
      </span>
      <Tooltip target=".earning-list" />
      <Button className='earning-list bg-BrandBlue text-white px-4 py-2' type="button" icon="pi pi-download" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
    </div>
  );

  const headerWithdrawalRequests = (
    <div className="flex justify-between -mx-4">
      <span className="p-input-icon-left flex items-center">
        <i className="pi pi-search" />
        <InputText className="w-full ring-0 border rounded-full pl-10 pr-4 py-3 font-normal" value={wrGlobalFilterValue} onChange={onWRGlobalFilterChange} placeholder="Search" />
      </span>
      <Tooltip target=".withdraw-list" />
      <Button className='withdraw-list bg-BrandBlue text-white px-4 py-2' type="button" icon="pi pi-download" rounded onClick={() => exportCSVWR(false)} data-pr-tooltip="CSV" />
    </div>
  );

  const [withdrawalRequests, setWithdrawalRequests] = useState();
  const [withdrawalTabLoading, setWithdrawalTabLoading] = useState(false);
  const onBeforeTabChanges = (e) => {
    const { index, originalEvent } = e;
    if(index == 1 ){ // withdrawal requests tab index
      getWithdrawalRequests();
    }
  }

  const getWithdrawalRequests = () => {
    setWithdrawalTabLoading(true);
    therest.get('/transactions/withrawal-requests', apiToken).then((response) => {
      console.log(response)
      if(response.status){
        setWithdrawalRequests(response.withdrawal_requests);
      }
      setWithdrawalTabLoading(false);
    });
    getEarnings();
  }

  const [changeStatusVisibility, setChageStatusVisibility] = useState(false);
  const items = [
    {
      label: 'In Progress'
    },
    {
      label: 'Processing'
    },
    {
      label: 'Released'
    }
  ];

  const statuses = [
    { name: 'In Progress', code: '2' },
    { name: 'Processing', code: '3' },
    { name: 'Released', code: '4' },
    { name: 'Cancelled', code: '5' },
  ];

  const onChangeStatus = (data) => {
    getTransactionFullDetails(data.transaction_id);
  }

  const [transactionFullDetails, setTransactionFullDetails] = useState();
  const getTransactionFullDetails = (transaction_id) => {
    therest.post('/transaction/withrawal-requests/full-details', {transaction_id: transaction_id}, apiToken).then((response) => {
      if(response.status){
        setTransactionFullDetails(response.transaction);
        setChageStatusVisibility(true);
      }
    });
  }

  const [newStatus, setNewStatus] = useState();
  const onChangeNewStatus = (e) => {
    setNewStatus(e.value);
  }

  const onStatusUpdate = (transaction_id) => {
    console.log(transactionFullDetails.transaction_id, newStatus);
    therest.post('/transaction/withrawal-requests/status-update', {transaction_id: transactionFullDetails.transaction_id, new_status: newStatus.code}, apiToken).then((response) => {
      if(response.status){
        getWithdrawalRequests();
        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message });
      } else {
        toast.current.show({ severity: 'error', summary: 'Failed!', detail: response.message });
      }
    });
    onModalHide();
  }

  const onModalHide = () => {
    setChageStatusVisibility(false);
    setNewStatus();
    setTransactionFullDetails();
    setChageStatusVisibility(false);
  }

  const renderWithdrawActions = (rowData) => {
    // console.log(rowData)
    return(
      <div className="flex gap-2">
        {
          rowData.withdrawable == 2 && user.is_admin != 1 && (
            <>
              <Button onClick={() => cancelTransaction(rowData.transaction_id)} icon="pi pi-times" label="" className="px-4 py-2 text-white bg-red-500" />
              {/* <Button label="x" className="mx-1 px-4 py-2 text-sm font-semibold rounded-lg text-white bg-red-700" severity="danger" onClick={() => cancelTransaction(rowData.transaction_id)}/> */}
            </>
          )
        }

        {
          user.is_admin == 1 && rowData.withdrawable != 5 && ( // is admin and not cancelled
            <>
              <Button label="Change Status" className="px-4 py-2 text-white bg-BrandBlue" onClick={() => onChangeStatus(rowData)}></Button>
              {/* <Button label="x" className="mx-1 px-4 py-2 text-sm font-semibold rounded-lg text-white bg-red-700" severity="danger" onClick={() => onChangeStatus(rowData)}/> */}
            </>
          )
        }
      </div>
    )
  }
  const accept = (transaction_id) => {
    toast.current.show({ severity: 'info', summary: 'Cancelling', detail: 'Cancelling Transaction In Progress', life: 2000 });
    therest.post('/transaction/cancel-withrawal-requests', {transaction_id: transaction_id}, apiToken).then((response) => {
      console.log(response);
      if(response.status){
        console.log(withdrawablepoint + response.amount_to_withdraw);
        setWithdrawablePoint(formatNumber(withdrawablepoint + response.amount_to_withdraw));
        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message });
        getWithdrawalRequests();
        
       
      } else {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: response.message });
      }
    });
  };

  const cancelTransaction = (transaction_id) => {
 
    confirmDialog({
      message: 'Are you sure you want to cancel your withdrawal request?',
      header: 'Cancel Request',
      icon: 'pi pi-info-circle',
      acceptClassName: 'mx-1 px-4 py-2 text-sm font-semibold rounded-sm text-white bg-red-700',
      rejectClassName: 'mx-1 px-4 py-2 text-sm font-semibold rounded-sm text-black bg-transparent',
      accept:()=>accept(transaction_id),
      // reject
    });
  };

  return(
  <>
    <Toast ref={toast} /> 
    {/* <ConfirmPopup /> */}
    <ConfirmDialog />
    <BreadCrumb links={[{title: 'Earnings', link: '/earnings'}]}/>
    <div className="w-full min-h-[50%] relative bg-white rounded-lg mt-8">
      <div className="w-full gap-4 flex items-center p-4 lg:p-8 pb-0 justify-between">
        <h1 className="lg:text-4xl text-xl font-bold text-sky-600">Earnings</h1>
      </div>

      <div className="lg:px-8 p-4">
        <div className="flex items-center gap-8">
          <div className="flex items-center gap-4">
            <span>Accumulated Points:</span>
            <Chip label={accumulatedPoints} />
          </div>
          
        </div>
        <div className="flex items-center gap-8">
          <div className="flex items-center gap-4 py-4">
            <span>Available Balance:</span>
            <Chip label={totalEarnings} />
          </div>
          
        </div>
        <div className="flex items-center gap-8">
          <div className="flex items-center gap-4 py-4">
            <span>Total Withdrawals:</span>
            <Chip label={totalWithdrawal} />
          </div>
          
        </div>
        <div className="flex items-center gap-8">
          <div className="flex items-center gap-4 py-4">
            <span>Withdrawable Points:</span>
            <Chip label={withdrawablepoint} />
          </div>
          <div>
          {
           user.is_admin != 1 && (
              <Button label="Withdraw" className={`px-8 py-4 rounded-full bg-BrandGreen text-white`} onClick={(e) => { 
                setViewWithdrawModal(true)
                // if(totalEarnings >= 1000){ 
                //   setViewWithdrawModal(true)
                // } 
                // else {  
                //   if(totalEarnings < 0){
                //     toast.current.show({ severity: 'error', summary: 'Failed!', detail: 'You haven not reached the minimum limit of 5,000.00' });
                //   } else {
                //     toast.current.show({ severity: 'error', summary: 'Failed!', detail: 'You should have atleast minimum of 3 members' });
                //   }
                // } 
              }} />

           )

            
          }
            
            
          </div>
        </div>
      </div>

        <div className='w-full px-4 lg:px-8'>
          <div className="overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:p-8">
            <TabView panelContainerClassName="px-4 border" onBeforeTabChange={onBeforeTabChanges}>
              <TabPanel
                pt={{
                  headerAction: ({ parent }) => ({
                    className: parent.state.activeIndex === 0 ? 'bg-BrandGreen text-white' : ''
                  })
              }} key={'1-1'} header={'Earnings'}>
                <DataTable filters={earningsFilters} ref={dt} header={header} headerClassName={'py-0'} className="bg-transparent" value={earnings} size={'medium'} paginator rows={10} rowsPerPageOptions={[10, 50, 100]} tableStyle={{ minWidth: '50rem' }} stripedRows>
                  <Column headerClassName="border-b" field="transaction_id" sortable header="Transaction ID" />
                  <Column headerClassName="border-b" field="description" sortable header="Description" />
                  {/* <Column headerClassName="border-b" field="commission_rate" sortable header="Rate" body={formatRate} /> */}
                  <Column headerClassName="border-b" field="user.name" sortable header="For Name" />
                  <Column headerClassName="border-b" field="commission_from.name" sortable header="From Name" />
                  <Column headerClassName="border-b" field="commission_from.referral_code" sortable header="From Code" />
                  <Column headerClassName="border-b" field="amount" sortable body={formatNumber} header="Amount" />
                  <Column headerClassName="border-b" field="created_at" sortable body={formatDateTime} header="Date/Time" />
                  <Column headerClassName="border-b" sortable body={renderStatus} header="Status" />
                </DataTable>
              </TabPanel>
              <TabPanel
              pt={{
                headerAction: ({ parent }) => ({
                  className: parent.state.activeIndex === 1 ? 'bg-BrandGreen text-white' : ''
                })
              }} key={'1-2'} header={'Withdrawal Requests'}>
                <div className="">
                  {
                    withdrawalTabLoading && (
                      <div className="h-[400px]">
                        Loading...
                      </div>
                    )
                  }

                  {
                    !withdrawalTabLoading && (
                      <DataTable filters={wrFilters} ref={dtW} header={headerWithdrawalRequests} headerClassName={'py-0'} className="bg-transparent" value={withdrawalRequests} size={'medium'} paginator rows={10} rowsPerPageOptions={[10, 50, 100]} tableStyle={{ minWidth: '50rem' }} stripedRows>
                        <Column headerClassName="border-b" field="transaction_id" sortable header="Transaction ID" />
                        <Column headerClassName="border-b" field="user.name" sortable header="Member Name" />
                        <Column headerClassName="border-b" field="mode_of_payment.name" sortable header="Mode of Payment" />
                        <Column headerClassName="border-b" field="amount" sortable body={formatNumber} header="Amount" />
                        <Column headerClassName="border-b" field="created_at" sortable body={formatDateTime} header="Request Date" />
                        <Column headerClassName="border-b" sortable body={renderWithdrawableStatus} header="Status" />
                        <Column headerClassName="border-b" body={renderWithdrawActions} header="" />
                      </DataTable>
                    )
                  }
                </div>
              </TabPanel>
            </TabView>
          </div>
        </div>
    </div>

    <Modals type='withdraw' data={withdrawData} setData={setWithdrawData} open={viewWithdrawModal} onHide={viewWithdrawModalOnHide} onSubmit={handleWithdrawSubmit} accounts={activeAccounts}/>
    <Dialog header="Change Withdrawal Status" className="lg:w-2/6 w-3/4" resizable={false} draggable={false} baseZIndex={999999} closable={true} dismissableMask={true} visible={changeStatusVisibility} onHide={() => onModalHide()}>
      {
        transactionFullDetails && (
          <>
            <Steps model={items} activeIndex={transactionFullDetails.withdrawable == 2 ? 0 : transactionFullDetails.withdrawable == 3 ? 1: 2}/>
            <div className="p-4">
              <div className="flex flex-col justify-center gap-4">
                <div className="border rounded-lg w-full">
                  <h1 className="text-xl font-bold p-4 text-center">Withdrawal Details</h1>
                  <div className="grid lg:grid-cols-3 gap-2 px-4">
                    <div>
                      <label>Mode of Payment:</label>
                      <InputText className="w-full ring-0 border rounded-full px-4 py-3 font-normal" readOnly value={transactionFullDetails.mode_of_payment.name} />
                    </div>
                    <div className="lg:col-span-2">
                      <label>Bank Name:</label>
                      <InputText className="w-full ring-0 border rounded-full px-4 py-3 font-normal" readOnly value={transactionFullDetails.requested_account.bank_name} />
                    </div>
                    <div className="lg:col-span-3">
                      <label>Account Name:</label>
                      <InputText className="w-full ring-0 border rounded-full px-4 py-3 font-normal" readOnly value={transactionFullDetails.requested_account.account_name} />
                    </div>
                    <div className="lg:col-span-3">
                      <label>Account Number:</label>
                      <InputText className="w-full ring-0 border rounded-full px-4 py-3 font-normal" readOnly value={transactionFullDetails.requested_account.account_number} />
                    </div>
                    <div className="lg:col-span-1">
                      <label>Amount Requested</label>
                      <p className="text-xl p-2">{Number(transactionFullDetails.amount || 0).toFixed(2)}</p>
                    </div>
                    <div className="lg:col-span-1">
                      <label>Admin Fee</label>
                      <p className="text-xl p-2">{Number(50).toFixed(2)}</p>
                    </div>
                    <div className="lg:col-span-1 text-right">
                      <label>Total Released</label>
                      <p className="font-bold text-xl p-2">{Number(transactionFullDetails.amount - 50).toFixed(2)}</p>
                    </div>
                  </div>
                  
                </div>

                <div className="w-full pt-4 flex gap-4 justify-center">
                  <Dropdown className="w-full ring-0 border w-full rounded-full px-4" value={newStatus} onChange={onChangeNewStatus} options={statuses.filter((s) => s.code != transactionFullDetails.withdrawable)} optionLabel="name" placeholder="Update Status" />
                  <Button label="Update" disabled={!newStatus} className={`px-4 py-2 text-white w-full rounded-full ${(newStatus && newStatus.code == 2 ? 'bg-gray-500' : (newStatus && newStatus.code == 3 ? 'bg-orange-500' : (newStatus && newStatus.code == 4 ? 'bg-BrandGreen' : (newStatus && newStatus.code == 5 ? 'bg-red-500' : 'bg-BrandBlue'))) )}`} onClick={onStatusUpdate}></Button>
                </div>
              </div>
            </div>
          </>
        )
      }
    </Dialog>
  </>
  )
}

export default Earnings