import React, { useState, useContext, useEffect, useRef } from "react";
import BreadCrumb from "../shared/BreadCrumb";
import { MdOutlineFileCopy, MdOutlineModeEditOutline, MdCheck } from "react-icons/md";
import Select from 'react-select';
import { UserContext } from '../../UserContext';
import { AuthContext } from "../../AuthContext";
import therest from "../../therest";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { BsCalendar4Event, BsQrCode } from "react-icons/bs";
import QRCode from 'qrcode.react';
import Modal from "../modals/Modal";
import { TabView, TabPanel } from 'primereact/tabview';
import { FaUser } from "react-icons/fa";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";
import { Card } from "primereact/card";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { ToggleButton } from "primereact/togglebutton";
import { LoadingContext } from "../../contexts/LoadingContext";
import { Calendar } from "primereact/calendar";

const Settings = () => {
  const { user, updateUser } = useContext(UserContext);
  const { apiToken } = useContext(AuthContext);
  const { setIsShowLoading } = useContext(LoadingContext);
  const { logout } = useContext(AuthContext);
  // const [currentPasswordValid, setCurrentPasswordValid] = useState(false);
  const options = [
    { value: 'national_id', label: 'National ID' },
    { value: "drivers_license", label: "Driver's License" },
    { value: 'philhealth_id', label: 'PhilHealth ID' }
  ]

  const handleLogout = () => {
    logout();
  }

  const copyToClipboard = (product_id) => {
    // const referralCode = `${String(product_id).padStart(4, '0')}-${String(submitUser.parent_id || 0).padStart(4, '0')}-${String(submitUser.id).padStart(4, '0')}`;
    const currentURL = window.location.href;
    const parsedUrl = new URL(currentURL);
    const baseUrl = parsedUrl.origin;

    const link = baseUrl + '/#/register/' + submitUser.referral_code;

    navigator.clipboard.writeText(link)
    .then(() => {
      alert('Referral code copied to clipboard');
    })
    .catch((error) => {
      console.error('Could not copy to clipboard: ', error);
    });
  };

  const header = <div className="font-bold mb-3">Pick a password</div>;

  user.currentpassword_valid = false;
  const [submitUser, setSubmitUser] = useState(user);

  // preselect the security questions that have already been selected
  const [securityQuestions, setSecurityQuestions] = useState(Array.from({ length: 5 }, (_, index) => submitUser[`sec_q${index + 1}`]));
  const fetchData = () => {
    therest.get('/security-questions/get-all', apiToken)
    .then(response => {
      if(response.length > 0){
        setSecurityQuestions(response.map(d => ({ 'value': d.id, 'label': d.question, isselected: false })));
      }
    }).catch(error => {
      console.error('Error:', error);
    });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    // if(name=="currentpassword"){
    
    // }

    if(name === 'currentpassword'){
      setValidatedInputs(prevInputs => ({ ...prevInputs, newpassword: false, confirmpassword: false }));
    }

    if (name === "newpassword" || name === "confirmpassword") {
      if (value.length < 6) {
        setValidatedInputs(prevInputs => ({ ...prevInputs, [name]: false }));
      } else {
        const { newpassword, confirmpassword } = submitUser;
  
        if (newpassword === confirmpassword) {
          const {newpassword, confirmpassword, ...remainingValidatedInputs } = validatedInputs;
          setValidatedInputs(remainingValidatedInputs);
        } else {
          setValidatedInputs(prevInputs => ({ ...prevInputs, confirmpassword: false }));
        }
      }
    }
  
    setSubmitUser(prevUser => ({
      ...prevUser,
      [name]: value
    }));
  };  
  
  const [validatedInputs, setValidatedInputs] = useState([]);
  const handleEmailInputChange = (e) => {
    const { name, value } = e.target;

    if(!value || !/^\S+@\S+\.\S+$/.test(value)) {
      const t = { ...validatedInputs, email: false};
      setValidatedInputs(t);
    } else {
      // remove email
      const { email, ...remainingValidatedInputs } = validatedInputs;
      setValidatedInputs(remainingValidatedInputs);
    }
    // Update the user state by creating a new object
    // with the updated property
    setSubmitUser(prevUser => ({
      ...prevUser,
      [name]: value
    }));
  }

  const validateFormData = (data, isPasswordUpdate) => {
    let updatedInputs = { ...validatedInputs };
  
    // Validate email
    if (!/^\S+@\S+\.\S+$/.test(data.email)) {
      updatedInputs = { ...updatedInputs, email: false };
    } else {
      const { email, ...remainingValidatedInputs } = updatedInputs;
      updatedInputs = remainingValidatedInputs;
    }
  
    // Validate new password
    if(data.newpassword && isPasswordUpdate){
      if (data.newpassword.length < 6) {
        updatedInputs = { ...updatedInputs, newpassword: false };
      } else {
        const { newpassword, ...remainingValidatedInputs } = updatedInputs;
        updatedInputs = remainingValidatedInputs;
        
        // Validate confirm password
        if (data.newpassword === data.confirmpassword) {
          const { confirmpassword, ...remainingValidatedInputs } = updatedInputs;
          updatedInputs = remainingValidatedInputs;
        } else {
          updatedInputs = { ...updatedInputs, confirmpassword: false };
        }
      }
    } 
    
    if(!isPasswordUpdate){
      const { newpassword, confirmpassword, ...remainingValidatedInputs } = updatedInputs;
      updatedInputs = remainingValidatedInputs;
    }
  
    setValidatedInputs(updatedInputs);
    return updatedInputs;
  };

  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const handleSelectChange = (e, name, isSecurityQuestion = false) => {
    const selectedValue = e && e.value ? e.value : 0;
    setSubmitUser(prevUser => ({
      ...prevUser,
      [name]: selectedValue
    }));
  
    if (isSecurityQuestion) {
      setSelectedQuestions(prevSelected => {
        // If an option was deselected, remove it from selectedQuestions
        if (selectedValue === 0) {
          return prevSelected.filter(value => value !== submitUser[name]);
        }
        // If a new option was selected, add it to selectedQuestions
        else if (!prevSelected.includes(selectedValue)) {
          return [...prevSelected.filter(value => value !== submitUser[name]), selectedValue];
        }
        // If an option was changed, replace the old value with the new one
        else {
          return prevSelected.map(value => value === submitUser[name] ? selectedValue : value);
        }
      });
      console.log(selectedQuestions);
    }
  };

  const validateSubmit = (updatedSubmitUser, isPasswordUpdate, currentpassword_valid = false) => {
    const vfd = validateFormData(updatedSubmitUser, isPasswordUpdate);
    if(Object.keys(vfd).length === 0) {
      if(!currentpassword_valid && isPasswordUpdate){
        return ;// the current password is not valid
      }
      therest.post('/user/update/' + updatedSubmitUser.id, updatedSubmitUser, apiToken)
      .then(response => {
        if (response.status) {
          updateUser(response.user);
          setSubmitUser(response.user);
          
          const { currentpassword, newpassword, confirmpassword, currentpassword_valid, ...userWithoutPasswords } = submitUser;
          setSubmitUser({
            ...userWithoutPasswords,
            currentpassword_valid: false,
          });

          toast.current.show({ severity: 'success', summary: 'Success', detail: response.message });
          setIsShowLoading(false);

          if(isPasswordUpdate){
            logout();
          }
        }
      })
      .catch(error => {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: error.message });
        setIsShowLoading(false); 
      });
    } else {
      if(vfd.newpassword === false){
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Password should be minimum of 6 characters' });
      } else if(vfd.confirmpassword === false){
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Confirm password should match the password' });
      } else {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please fill-up the required fields!' }); 
      }

      setIsShowLoading(false);
    } 
  }

  const toast = useRef(null);
  const handleSubmit = (isPasswordUpdate) => {
    const updatedSubmitUser = { ...submitUser, photoid: imagePreview, photoprofile: profilePreview};
    console.table(updatedSubmitUser);
    setSubmitUser(updatedSubmitUser);
    setIsShowLoading(true); 
    if (isPasswordUpdate) {
      therest.post('/user/checkcurrentpassword', { password: updatedSubmitUser.currentpassword }, apiToken)
      .then(response => {
        setSubmitUser(prevSubmitUser => ({
          ...prevSubmitUser,
          currentpassword_valid: response.status
        }));

        if (!response.status) {
          toast.current.show({ severity: 'error', summary: 'Failed', detail: response.message });
          return; // Stop execution if the password is not valid
        } else {
          validateSubmit(submitUser, isPasswordUpdate, response.status);
        }
      })
      .catch(error => {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: error.message });
      });
    } else {
      // THIS will remove password if Save Settings clicked on Profile Tab 
      const updatedSubmitUser = { ...submitUser, photoid: imagePreview, photoprofile: profilePreview};
      const { currentpassword, newpassword, confirmpassword, currentpassword_valid, ...userWithoutPasswords } = updatedSubmitUser;
      setSubmitUser({
        ...userWithoutPasswords,
        currentpassword_valid: false,
      });

      validateSubmit(userWithoutPasswords, isPasswordUpdate);
    }
  }

  

  const [imagePreview, setImagePreview] = useState(user.idurl || 'https://placehold.co/552x368');
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const [profilePreview, setProfilePreview] = useState(user.profile_url || 'https://placehold.co/100');
  const handleProfileImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfilePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const [startDate, setStartDate] = useState(new Date(user.birthdate));
  const handleDateChange = (date) => {
    setStartDate(date);
    const newDate = formatDate(date);
    const updatedSubmitUser = { ...submitUser, birthdate: newDate };
    setSubmitUser(updatedSubmitUser);
  }

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are 0-indexed
    const day = date.getDate();

    // Ensure month and day are two digits
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    return `${year}-${formattedMonth}-${formattedDay} 00:00:00`;
  }

  const [openQR, setOpenQR] = useState(false);
  const [qrCodeData, setQrCodeData] = useState('');
  const handleOpenQR = (referralcode) => {
    const code = referralcode;//`${String(product_id).padStart(4, '0')}-${String(submitUser.parent_id || 0).padStart(4, '0')}-${String(submitUser.id).padStart(4, '0')}`;
    const currentURL = window.location.href;
    const parsedUrl = new URL(currentURL);
    const baseUrl = parsedUrl.origin;

    const link = baseUrl + '/#/register/' + code;
    console.log(link);
    setQrCodeData(link);

    setOpenQR(true);
  }

  const handleModalClose = () => {
    setOpenQR(false);
  }

  const [products, setProducts] = useState();
  const getProducts = () => {
    therest.get('/active-products', apiToken).then((response) => {
      setProducts(response.products);
    });
  }
  
  const cardHeader = (url) => {
    return(
      <img className="w-full h-[300px] object-cover rounded-t-lg" alt="Card" src={url || 'https://placehold.co/552x368' } />
    );
  } 

  const cardFooter = (data) => {
    return(
      <div className="flex justify-center gap-2">
        <MdOutlineFileCopy onClick={() => copyToClipboard(data.id)} className="cursor-pointer text-BrandGreen" size={20}/>
        <BsQrCode onClick={() => handleOpenQR(data.id)} className="cursor-pointer text-BrandGreen" size={20}/>
      </div>
    );
  } 

  useEffect(() => {
    fetchData();
    getProducts();
  }, []);
  
  useEffect(() => {
    const selectedValues = Array.from({ length: 5 }, (_, index) => submitUser[`sec_q${index + 1}`]);
    setSelectedQuestions(selectedValues);
  }, [submitUser]);

  const [statuses, setStatuses] = useState({
    bank_transfer: false,
    gcash_maya: false,
    pickup: false
  });

  const handleSwitchStatusChange = (keyToUpdate, value) => {
    setStatuses((prevStatuses) => ({
      ...prevStatuses,
      [keyToUpdate]: value
    }));
  }

  const [bankTransferData, setBankTransferData] = useState({
    bank_name: '',
    account_name: '',
    account_number: '',
    status: false
  });

  const [eWalletData, setEWalletData] = useState({
    bank_name: '',
    account_name: '',
    account_number: '',
    status: false
  });

  const handleOnChange = (target, value, type) => {
    if(type == "bank_transfer"){
      setBankTransferData((prevBankTransferData) => ({
        ...prevBankTransferData,
        [target]: value
      }));
    }

    if(type == "e_wallet"){
      setEWalletData((prevEWalletData) => ({
        ...prevEWalletData,
        [target]: value
      }));
    }
  }

  const getAccounts = () => {
    therest.get('/withdrawal-accounts/' + user.id , apiToken).then((response) => {
      if (response.status && response.accounts) {
        const bank_transfer = response.accounts.find(d => d.type == 2);
        const e_wallet = response.accounts.find(d => d.type == 3);

        if (bank_transfer) {
          setBankTransferData(prevBankTransferData => ({
            ...prevBankTransferData,
            bank_name: bank_transfer.bank_name || '',
            account_name: bank_transfer.account_name || '',
            account_number: bank_transfer.account_number || '',
            status: bank_transfer.status === 1,
          }));
        }

        if (e_wallet) {
          setEWalletData(prevEWalletData => ({
            ...prevEWalletData,
            bank_name: e_wallet.bank_name || '',
            account_name: e_wallet.account_name || '',
            account_number: e_wallet.account_number || '',
            status: e_wallet.status === 1,
          }));
        }
      }
    });
  }

  useEffect(() => {
    getAccounts();
  }, []);

  const ewallets = [{ name: 'Gcash', code: 'gcash' },{ name: 'Maya', code: 'maya' }];
  const [eWalletIsUpdate, setEWalletIsUpdate] = useState(false);
  const [banktransferIsUpdate, setBanktrasnferIsUpdate] = useState(false);

  const handleBankTransferSubmit = (value) => { 
    if(!value){ // will trigger on clicking update
      setIsShowLoading(true);
      therest.post('/withdrawal-account/' + user.id + '/' + '2', bankTransferData, apiToken).then((response) => {
        if(response.status){
          toast.current.show({ severity: 'success', summary: 'Success', detail: response.message});
        }
        setIsShowLoading(false);
      });
      setIsShowLoading(false);
    }
    setBanktrasnferIsUpdate(value); 
  }

  const handleEWalletSubmit = (value) => { 
    if(!value){ // will trigger on clicking update
      setIsShowLoading(true);
      therest.post('/withdrawal-account/' + user.id + '/' + '3', eWalletData, apiToken).then((response) => {
        if(response.status){
          toast.current.show({ severity: 'success', summary: 'Success', detail: response.message});
        }
        setIsShowLoading(false);
      });
      setIsShowLoading(false);
    }
    setEWalletIsUpdate(value); 
  }

  const tabs = [
    { title: 'Profile', icon: <FaUser className="mr-2"/>, 
      content: (
        <div className="w-full flex flex-col lg:flex-row gap-4 lg:gap-8 p-4 lg:p-8">
          <div>
            <input
              type="file"
              accept="image/*"
              onChange={handleProfileImageUpload}
              style={{ display: 'none' }}
              id="imageUpload"
            />
            <label htmlFor="imageUpload">
              <img alt={''} className='w-[100px] h-[100px] object-cover rounded-full' height="50" src={profilePreview} />
            </label>
          </div>
          <div className="flex flex-col gap-4 w-full mt-4 lg:mt-0">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <span className="font-bold">Referral Code</span>
                <span className="flex items-center gap-3">
                  <span className="py-2 px-4 border border-[#ccc] rounded-full bg-gray-200">
                    {submitUser.referral_code || ''}
                  </span>
                  <MdOutlineFileCopy onClick={() => copyToClipboard(products[0].id)} className="cursor-pointer text-BrandGreen" size={20}/>
                  <BsQrCode onClick={() => handleOpenQR(submitUser.referral_code)} className="cursor-pointer text-BrandGreen" size={20}/>
                </span>
              </div>
              {/* <button onClick={() => handleSubmit(false)} className="bg-BrandGreen text-white px-8 py-4 rounded-full">Save Settings</button> */}
            </div>
            
            <div className="grid lg:grid-cols-8 grid-cols-1 gap-4">
              <div className="lg:col-span-4 col-span-1">
                <label labelfor="username" className="block mb-2 ml-3 text-sm font-medium text-gray-900">Enter Full Name</label>
                <input type="text" value={submitUser.name || ''} name="name" onChange={handleInputChange} id="username" className="bg-gray-50 border-[1px] border-gray-300 text-gray-900 text-sm rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full p-4 px-4" placeholder="Enter Full Name" autoComplete='new-password' required />
              </div>
              <div className="lg:col-span-2 col-span-1">
                <label labelfor="birthdate" className="block mb-2 ml-3 text-sm font-medium text-gray-900">Birth Date</label>
                <Calendar className="border rounded-full px-4 py-3" showIcon showButtonBar placeholder="mm/dd/yyyy" value={startDate} onChange={(e) => handleDateChange(e.value)} />
                
                {/* <DatePicker calendarIcon={<BsCalendar4Event size={20}/>} onChange={handleDateChange} value={startDate} className="bg-gray-50 border-[1px] border-gray-300 text-gray-900 text-sm rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full py-3 px-4"/> */}
                {/* <input type="text" value={submitUser.birthdate || ''} name="birthdate" onChange={handleInputChange} id="birthdate" className="bg-gray-50 border-[1px] border-gray-300 text-gray-900 text-sm rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full p-4 px-4" placeholder="MMM / DD / YYYY" autoComplete='new-password' required /> */}
              </div>
              <div className="lg:col-span-2 col-span-1">
                <label labelfor="nationality" className="block mb-2 ml-3 text-sm font-medium text-gray-900">Nationality</label>
                <input type="text" value={submitUser.nationality || ''} name="nationality" onChange={handleInputChange} id="nationality" className="bg-gray-50 border-[1px] border-gray-300 text-gray-900 text-sm rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full p-4 px-4" placeholder="Filipino" autoComplete='new-password' required />
              </div>

              <div className="lg:col-span-4 col-span-1">
                <label labelfor="address" className="block mb-2 ml-3 text-sm font-medium text-gray-900">Address</label>
                <input type="text" value={submitUser.address || ''} name="address" onChange={handleInputChange} id="address" className="bg-gray-50 border-[1px] border-gray-300 text-gray-900 text-sm rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full p-4 px-4" placeholder="Your Address Here" autoComplete='new-password' required />
              </div>
              <div className="lg:col-span-3 col-span-1">
                <label labelfor="city" className="block mb-2 ml-3 text-sm font-medium text-gray-900">City / Municipality</label>
                <input type="text" value={submitUser.city || ''} name="city" onChange={handleInputChange} id="city" className="bg-gray-50 border-[1px] border-gray-300 text-gray-900 text-sm rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full p-4 px-4" placeholder="Mandaue City, Cebu" autoComplete='new-password' required />
              </div>
              <div>
                <label labelfor="zipcode" className="block mb-2 ml-3 text-sm font-medium text-gray-900">Zip Code</label>
                <input type="text" value={submitUser.zipcode || ''}  name="zipcode" onChange={handleInputChange} id="zipcode" className="bg-gray-50 border-[1px] border-gray-300 text-gray-900 text-sm rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full p-4 px-4" placeholder="0 0 0 0" autoComplete='new-password' required />
              </div>

              <div className="lg:col-span-4 col-span-1">
                <label labelfor="mobile_number" className="block mb-2 ml-3 text-sm font-medium text-gray-900">Mobile Number</label>
                <input type="text" value={submitUser.mobile_number || ''}  name="mobile_number" onChange={handleInputChange} id="mobile_number" className="bg-gray-50 border-[1px] border-gray-300 text-gray-900 text-sm rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full p-4 px-4" placeholder="+639 12345 6789" autoComplete='new-password' required />
              </div>
              <div className="lg:col-span-4 col-span-1">
                <label labelfor="email" className="block mb-2 ml-3 text-sm font-medium text-gray-900">Email Address</label>
                <input type="email" value={submitUser.email || ''} name="email" onChange={handleEmailInputChange} id="email" className={`${validatedInputs && validatedInputs.email === false ? 'border-red-500' : 'border-gray-300'} bg-gray-50 border-[1px] text-gray-900 text-sm rounded-full focus-visible:ring-[1px] focus:border-red-500 focus-visible:border-[1px] block w-full p-4 px-4`} placeholder="mailingaddress@email.com" autoComplete='new-password' required />
              </div>

              <div className="lg:col-span-4 col-span-1">
                <label labelfor="idtype" className="block mb-2 ml-3 text-sm font-medium text-gray-900">ID Submitted</label>
                <Select
                  options={options} 
                  isClearable={true} 
                  defaultValue={options.filter(option => 
                    option.value === submitUser.idtype || ''
                  )}
                  onChange={(e) => handleSelectChange(e, 'idtype')}
                  name="idtype"
                  placeholder="Identification ID Type"
                  styles={{
                    control: (styles) => ({ 
                      ...styles, 
                      backgroundColor: 'transparent',
                      borderRadius: '9999px',
                      padding: 10,
                    }),
                    indicatorSeparator: (styles) => ({
                      ...styles,
                      width: 0,
                    }),
                  }}
                />
              </div>
              <div className="lg:col-span-4 col-span-1 flex flex-col">
                <label htmlFor="idurl" className="block mb-2 ml-3 text-sm font-medium text-gray-900">ID Preview</label>
                <div className="border-[1px] border-gray-300 rounded-lg h-[368px] flex justify-center p-4">
                  <img alt={''} className="rounded-lg object-cover" src={imagePreview} />
                </div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  style={{ display: 'none' }}
                  id="idurl"
                />
                <label htmlFor="idurl" className="rounded-full text-center text-white px-8 py-2 bg-BrandGreen mt-4 cursor-pointer">
                  Upload ID
                </label>
              </div>
              
              <div className="lg:col-span-8 col-span-1 mt-4 text-right">
                <button onClick={() => handleSubmit(false)} className="bg-BrandGreen text-white px-8 py-4 rounded-full">Save Settings</button>
              </div>
            </div>
          </div>
        </div>
      ) 
    },
    { title: 'Security', icon: <FaUser className="mr-2"/>, content: (
      <>
        <div className="p-4 lg:p-8">
          <div className="grid lg:grid-cols-8 gap-4 mb-4">
          <div className="lg:col-span-2 flex flex-col">
              <label htmlFor="password">Current Password</label>
              <Password id="currentpassword" name="currentpassword" feedback={false} inputClassName="ring-0 w-full pr-4" className="border rounded-full px-4 py-3" value={submitUser.currentpassword} onChange={handleInputChange} toggleMask/>
            </div>
            <div className="lg:col-span-2 flex flex-col">
              <label htmlFor="password">New Password</label>
              <Password id="password" name="newpassword" feedback={false} inputClassName="ring-0 w-full pr-4" className="border rounded-full px-4 py-3" value={submitUser.newpassword} onChange={handleInputChange} toggleMask/>
            </div>
            <div className="lg:col-span-2 flex flex-col">
              <label htmlFor="confirmpassword">Confirm New Password</label>
              <Password id="confirmpassword" name="confirmpassword" feedback={false} inputClassName="ring-0 w-full pr-4" className="border rounded-full px-4 py-3" value={submitUser.confirmpassword} onChange={handleInputChange} toggleMask/>
            </div>

            <div className="flex items-end">
              <button onClick={() => handleSubmit(true)} className="bg-BrandGreen text-white px-8 py-4 rounded-full">Save Settings</button>
            </div>
          </div>

          <h1 className="text-2xl font-bold text-sky-600 pb-3 border-b-[2px] border-BrandBlue">Security Questions</h1>
          <div className="accordion py-4">
          {
            Array.from({ length: 3 }, (_, index) => {
              var indexvalue = submitUser[`sec_q${index + 1}`];
              var defaultValue = '';
              // alert(indexvalue);
              if (indexvalue !== null && indexvalue !== "") {
                defaultValue = securityQuestions.find(option => option && option.value === indexvalue);
              }
              
              const filteredSecurityQuestions = securityQuestions.filter(
                question => !selectedQuestions.includes(question && question.value)
              );
              return (
                <div className="accordion-item py-4 border-b" key={index}>
                  <div className="accordion-title">
                    <Select
                      options={filteredSecurityQuestions}
                      isClearable={true}
                      value={defaultValue}
                      defaultValue={defaultValue}
                      onChange={e => handleSelectChange(e, `sec_q${index + 1}`, true)}
                      name={`sec_q${index + 1}`}
                      id={`sec_q${index + 1}`}
                      placeholder={`Security Question #${index + 1}`}
                      styles={{
                        control: styles => ({
                          ...styles,
                          backgroundColor: 'transparent',
                          border: 'none',
                          boxShadow: 'transparent !important',
                        }),
                        indicatorSeparator: styles => ({
                          ...styles,
                          width: 0,
                        }),
                      }}
                    />
                  </div>
                  <div className="accordion-content p-4">
                    <input
                      className="outline-none"
                      onChange={handleInputChange}
                      value={submitUser[`sec_q${index + 1}_ans`]}
                      name={`sec_q${index + 1}_ans`}
                      type="password"
                      placeholder="Your answer here."
                    />
                  </div>
                </div>
              );
            })
          }
          </div>
        </div>
        <div className="px-8 w-full py-4 text-right">
          <button onClick={() => handleSubmit(false)} className="bg-BrandGreen text-white px-8 py-4 rounded-full">Save Settings</button>
        </div>
      </>
    ) },
     { title: 'Withdrawal Methods', icon: <FaUser className="mr-2"/>, content: 
      (
        <div className="p-4 lg:p-8">
          <Accordion multiple className="w-full">
            <AccordionTab header="Bank Transfer">
              <div className="px-4">
                <div className={`${banktransferIsUpdate ? '' : 'cursor-not-allowed'} flex gap-4 items-center mb-4`} >
                  <label htmlFor="account_status" className="font-semi-bold">Enable</label>
                  <InputSwitch checked={bankTransferData.status} disabled={!banktransferIsUpdate} onChange={(e) => handleOnChange('status', e.value, 'bank_transfer')} />
                </div>
                <div className="grid grid-cols-4 col-12 md:col-4 lg:col-4 gap-4">
                  <div className={`${banktransferIsUpdate ? '' : 'cursor-not-allowed'}`}>
                    <label htmlFor="bank_name">Bank Name</label>
                    <InputText value={bankTransferData.bank_name} onChange={(e) => handleOnChange('bank_name', e.target.value, 'bank_transfer')} disabled={!banktransferIsUpdate} className="w-full ring-0 border rounded-full px-4 py-3" id="bank_name"/>
                  </div>
                  <div className={`${banktransferIsUpdate ? '' : 'cursor-not-allowed'}`}>
                    <label htmlFor="account_name">Account Name</label>
                    <InputText value={bankTransferData.account_name} onChange={(e) => handleOnChange('account_name', e.target.value, 'bank_transfer')} disabled={!banktransferIsUpdate} className="w-full ring-0 border rounded-full px-4 py-3" id="account_name"/>
                  </div>
                  <div className={`${banktransferIsUpdate ? '' : 'cursor-not-allowed'}`}>
                    <label htmlFor="account_number">Account Number</label>
                    <InputText value={bankTransferData.account_number} onChange={(e) => handleOnChange('account_number', e.target.value, 'bank_transfer')} disabled={!banktransferIsUpdate} className="w-full ring-0 border rounded-full px-4 py-3" id="account_number"/>
                  </div>
                  <div className="flex items-end gap-4">
                    <ToggleButton onLabel="Update" className={`p-4 text-sm font-semibold rounded-full ${banktransferIsUpdate ? 'bg-BrandGreen' : 'bg-BrandBlue'} text-white w-[200px]`} offLabel="Edit" onIcon={<MdCheck/>} offIcon={<MdOutlineModeEditOutline/>} checked={banktransferIsUpdate} onChange={(e) => handleBankTransferSubmit(e.value)} />
                  </div>
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Gcash/Maya">
              <div className="px-4">
                <div className={`${eWalletIsUpdate ? '' : 'cursor-not-allowed'} flex gap-4 items-center mb-4`}>
                  <label htmlFor="account_status" className="font-semi-bold">Enable</label>
                  <InputSwitch checked={eWalletData.status} disabled={!eWalletIsUpdate} onChange={(e) => handleOnChange('status', e.value, 'e_wallet')} />
                </div>
                <div className="grid grid-cols-4 gap-4">
                  <div className={`${eWalletIsUpdate ? '' : 'cursor-not-allowed'}`}>
                    <label htmlFor="bank_name">E-Wallet</label>
                    <Dropdown value={ewallets.find(d => d.code === eWalletData.bank_name)} onChange={(e) => handleOnChange('bank_name', e.value.code, 'e_wallet')} disabled={!eWalletIsUpdate} className="w-full ring-0 border rounded-full px-4" options={ewallets} optionLabel="name" 
                      placeholder="Select E-Wallet" />
                  </div>
                  <div className={`${eWalletIsUpdate ? '' : 'cursor-not-allowed'}`}>
                    <label htmlFor="account_name">Account Number/Phone Number</label>
                    <InputText value={eWalletData.account_number} onChange={(e) => handleOnChange('account_number', e.target.value, 'e_wallet')} disabled={!eWalletIsUpdate} className="w-full ring-0 border rounded-full px-4 py-3" id="account_number"/>
                  </div>
                  <div className="flex items-end gap-4">
                    <ToggleButton onLabel="Update" className={`p-4 text-sm font-semibold rounded-full ${eWalletIsUpdate ? 'bg-BrandGreen' : 'bg-BrandBlue'} text-white w-[200px]`} offLabel="Edit" onIcon={<MdCheck/>} offIcon={<MdOutlineModeEditOutline/>} checked={eWalletIsUpdate} onChange={(e) => handleEWalletSubmit(e.value)} />
                  </div>
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Cash Pickup Office" disabled={false}>
              <div className="px-4">
                <div className="flex gap-4 items-center mb-4">
                  <label htmlFor="account_status" className="font-semi-bold">Enable</label>
                  <InputSwitch checked={statuses.pickup} onChange={(e) => handleSwitchStatusChange('pickup', e.value)} />
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>
      ) 
    }
    // { title: 'Product', icon: <FaUser className="mr-2"/>, content: 
    //   (
    //     <div className="p-4 lg:p-8">
    //       <div className="grid lg:grid-cols-4 gap-4">
    //       {
    //         products && products.map((product, index) => {
    //           return(
    //             <div className="shadow-lg" key={index}>
    //               <Card className="text-BrandBlue text-center" title={product.name}subTitle={'P ' + Number(product.price).toFixed(2)} header={() => cardHeader(product.photo_url)} footer={() => cardFooter(product)}/>
    //             </div>
    //           )
    //         })
    //       }
    //       </div>
    //     </div>
    //   ) 
    // }
  ];

  const panelClassName = (parent, index) => {
    if (parent.state.activeIndex === index)
      return 'bg-BrandGreen text-white'
  }

  return(
    <>
      <BreadCrumb links={[{title: 'Settings', link: '/settings'}]}/>
      <div className="w-full min-h-[50%] relative bg-white rounded-lg mt-8">
        <div className="w-full gap-4 flex items-center p-4 lg:p-8 pb-0 justify-between">
          <h1 className="lg:text-4xl text-xl font-bold text-sky-600">Settings</h1>
        </div>
        <div className="mb-5 px-4 lg:px-8 pb-8">
          <div className="border rounded-lg">
            <TabView panelContainerClassName="p-0">
              {tabs.map((tab, i) => {
                return (
                  <TabPanel
                    pt={{
                      headerAction: ({ parent }) => ({
                          className: panelClassName(parent, i)
                      })
                    }}
                    key={i} header={tab.title}>
                      {tab.content}
                  </TabPanel>
                )
              })}
            </TabView>
          </div>
        </div>
      </div>

      <Modal 
        openModal={openQR} 
        onClose={() => {}} // empty prevents outside click
        onCancel={handleModalClose} 
        title={<div>&nbsp;</div>}
        noSubmit={true}
        children={
          <div className="w-full text-center flex justify-center">
            <QRCode value={qrCodeData} size={300}/>
          </div>
        }
      />

      <Toast ref={toast} />
    </>
  )
}

export default Settings;