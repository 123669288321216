import React, { useState, useEffect, useRef, useContext } from "react";
import Select from 'react-select';
import { MdUploadFile } from "react-icons/md";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import therest from "../../therest";
import { useParams } from "react-router-dom";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import Swal from "sweetalert2";

import { LoadingContext } from "../../contexts/LoadingContext";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";

const Register = () => {
  const { setIsShowLoading } = useContext(LoadingContext);
  const toast = useRef(null); 

  const { referrerCode } = useParams();

  const [newUser, setNewUser] = useState({});
  const options = [
    { value: '1', label: 'National ID' },
    { value: '2', label: "Driver's License" },
    { value: '3', label: 'PhilHealth ID' }
  ]

  const [startDate, setStartDate] = useState(new Date());
  const handleDateChange = (date) => {
    setStartDate(date);
    const newDate = formatDate(date);
    const updatedNewUser = { ...newUser, birthdate: newDate };
    setNewUser(updatedNewUser);
  }

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    return `${year}-${formattedMonth}-${formattedDay} 00:00:00`;
  }

  const [securityQuestions, setSecurityQuestions] = useState([]);
  const fetchData = () => {
    therest.get('/security-questions/get-all')
    .then(response => {
      if(response.length > 0){
        setSecurityQuestions(response.map(d => ({ 'value': d.id, 'label': d.question })));
      }
    }).catch(error => {
      console.error('Error:', error);
    });
  } 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser(prevUser => {
      const updatedUser = {
        ...prevUser,
        [name]: value
      };
  
      const validationErrors = validateFormData(updatedUser);
      setError(validationErrors);
  
      return updatedUser;
    });
  }

  const handleInputAnswerChange = (e) => {
    const { name, value } = e.target;
    setNewUser(prevUser => ({
      ...prevUser,
      [name]: value
    }));
  }

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setNewUser(prevUser => ({
      ...prevUser,
      [name]: checked
    }));
  }

  const [imagePreview, setImagePreview] = useState(null);
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
        // console.log(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const [profilePreview, setProfilePreview] = useState('/person.png');
  const [profileName, setProfileName] = useState('..\\person.png');
  const handleProfileImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfilePreview(reader.result);
        setProfileName(file.name);
        // console.log(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const handleSelectChange = (e, name, isSecurityQuestion = false) => {
    const selectedValue = e && e.value ? e.value : 0;
    setNewUser(prevUser => ({
      ...prevUser,
      [name]: selectedValue
    }));
  
    if (isSecurityQuestion) {
      setSelectedQuestions(prevSelected => {
        // If an option was deselected, remove it from selectedQuestions
        if (selectedValue === 0) {
          return prevSelected.filter(value => value !== newUser[name]);
        }
        // If a new option was selected, add it to selectedQuestions
        else if (!prevSelected.includes(selectedValue)) {
          return [...prevSelected.filter(value => value !== newUser[name]), selectedValue];
        }
        // If an option was changed, replace the old value with the new one
        else {
          return prevSelected.map(value => value === newUser[name] ? selectedValue : value);
        }
      });
      // console.log(selectedQuestions);
    }
    const validationErrors = validateFormData({...newUser}); 
    setError(validationErrors)
  };

  const [errors, setError] = useState({});

  const handleSubmit = () => {
    
    const updatedSubmitUser = { ...newUser, photoid: imagePreview, photoprofile: profilePreview };
    var proceedSave = false;
    console.log(updatedSubmitUser);
    if(updatedSubmitUser.referral_code==null||updatedSubmitUser.referral_code.trim()==''){
      Swal.fire({
        title: "You do not have a valid referral code. Do you want to proceed?",
        showDenyButton: true,
        confirmButtonText: "Continue without referral",
        denyButtonText: `Enter referral code`
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          proceedSave=true;
          submitUser(updatedSubmitUser)
        }
      });
    }else{
      submitUser(updatedSubmitUser)
    }
  }
  const submitUser = (updatedSubmitUser) => {
    
      const validationErrors = validateFormData(updatedSubmitUser);
      if (Object.keys(validationErrors).length === 0) {
        // Data is valid
        toast.current.show({ severity: 'success', summary: 'Registering User', detail: "Saving details. Please wait"});
        setIsShowLoading(true);
        therest.post('/register/user', updatedSubmitUser).then((response) => {
          // console.log(response);
          if(response.status){
            toast.current.show({ severity: 'success', summary: 'Register Successful', detail: "Redirecting to Home Page"});
            window.location.replace('/');
          } else {
            // Notification('Register failed', response.message, 'danger');
            toast.current.show({ severity: 'error', summary: 'Register failed', detail: response.message });
          }
          setIsShowLoading(false);
        }).catch((reason) => {
          // Notification('Register failed', reason.message, 'danger');
          toast.current.show({ severity: 'error', summary: 'Register failed', detail: reason.message });
          setIsShowLoading(false);
        });
      } else {
        // Handle validation errors, e.g., display error messages
        console.log('Validation Errors:', validationErrors);
        setError(validationErrors);
        // Notification('Empty fields', 'Please fill up required fields', 'danger');
        if(validationErrors.terms_and_conditions && validationErrors.terms_and_conditions != "" && validationErrors.terms_and_conditions != null){
          Swal.fire({
            title: 'Warning',
            text: validationErrors.terms_and_conditions,
            confirmButtonText: "Close"
          });
          
        } else {
          toast.current.show({ severity: 'error', summary: 'Empty fields', detail: 'Please fill up required fields' });
        }
        setIsShowLoading(false);
        return;
      }
  }

  const validateFormData = (data) => {
    const errors = {};
  
    if (!data.terms_and_conditions) {
      errors.terms_and_conditions = 'You must agree to our Terms and Conditions as well as our Refund Policy';
    }

    // Validate email
    if (!data.email || !/^\S+@\S+\.\S+$/.test(data.email)) {
      errors.email = 'Invalid email address';
    }
  
    // Validate required fields
    const requiredFields = ['name', 'nationality', 'address', 'city', 'zipcode', 'mobile_number',  'birthdate'];
    requiredFields.forEach(field => {
      if (!data[field]) {
        errors[field] = `${(field.charAt(0).toUpperCase() + field.slice(1)).replace('_', ' ')} is required`;
      }
    });

    if (!data.password) {
      errors.password = 'Password is required';
    } else if (data.password.length < 6) {
      errors.password = 'Password must be at least 6 characters long';
    }
    
    // Validate password confirmation
    if (!data.confirmpassword) {
      errors.confirmpassword = 'Confirm password is required';
    } else if (data.password !== data.confirmpassword) {
      errors.confirmpassword = 'Passwords do not match';
    }
  
    // Validate sec_q1 to sec_q5 are numbers
    for (let i = 1; i <= 1; i++) {
      const questionField = `sec_q${i}`;
      const answerField = `sec_q${i}_ans`;
  
      if (!data[questionField]) {
        errors[questionField] = 'Please set a security question';
      }
  
      if (!data[answerField] && !data[questionField]) {
        errors[answerField] = `This field is required`;
      }
    }
    return errors;
  }

  useEffect(() => {
    const newDate = formatDate(startDate);
    const updatedNewUser = { ...newUser, birthdate: newDate, referral_code: referrerCode };
    setNewUser(updatedNewUser);
     
    fetchData();
  }, []);

  return (
  <>
    <Toast ref={toast} />
    <div className='w-full h-screen bg-BrandBlue lg:p-8 p-4 overflow-y-auto'>
      <div className="lg:w-2/3 mx-auto w-full lg:p-[40px] p-4 bg-white border border-gray-200 rounded-[16px] shadow">
        <div className="space-y-6">
          <div className="grid grid-cols-2 lg:grid-cols-8 gap-4">
            <div className="lg:col-span-1">
              <img alt={''} className='w-[100px] h-[100px] rounded-full object-contain border' src={profilePreview} />
            </div>
            <div className="lg:col-span-3">
              <label htmlFor="profile_url" className="block mb-2 ml-3 text-sm font-medium text-gray-900">Upload profile pic</label>
              <label htmlFor="profile_url">
                <div className="p-4 flex justify-between items-center border rounded-full">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleProfileImageUpload}
                    style={{ display: 'none' }}
                    id="profile_url"
                  />
                  <label htmlFor="profile_url" className="cursor-pointer overflow-hidden">
                    {profileName ? profileName : "..\\profile_image.jpg"}
                  </label>
                  <MdUploadFile size={20} className="text-BrandGreen"/>
                </div>
              </label>  
            </div>
            <div className="col-span-2 lg:col-span-4">
              <label labelfor="referral_code" className="block mb-2 ml-3 text-sm font-medium text-gray-900">Referral Code </label>
              <input type="text" defaultValue={referrerCode} onKeyUp={handleInputChange} name="referral_code" id="referral_code" className="bg-gray-50 border-[1px] border-gray-300 text-gray-900 text-sm rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full p-4 px-4" placeholder="----" autoComplete='new-password' required />
            </div>

            <div className="col-span-2 lg:col-span-4">
              <label labelfor="name" className="block mb-2 ml-3 text-sm font-medium text-gray-900">Enter Full Name <em>*</em></label>
              <input type="text" onKeyUp={handleInputChange} name="name" id="name" className="bg-gray-50 border-[1px] border-gray-300 text-gray-900 text-sm rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full p-4 px-4" placeholder="Enter Full Name" autoComplete='new-password' required />
              {/* <small className="ml-3 text-red-500">{errors.name}</small> */}
            </div>
            <div className="col-span-2 lg:col-span-4">
              <label labelfor="mobile_number" className="block mb-2 ml-3 text-sm font-medium text-gray-900">Mobile Number <em>*</em></label>
              <input type="text" onKeyUp={handleInputChange} name="mobile_number" id="mobile_number" className="bg-gray-50 border-[1px] border-gray-300 text-gray-900 text-sm rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full p-4 px-4" placeholder="+639 12345 6789" autoComplete='new-password' required />
              {/* <small className="ml-3 text-red-500">{errors.mobile_number}</small> */}
            </div>
            <div className="col-span-2 lg:col-span-2">
              <label labelfor="birthdate" className="block mb-2 ml-3 text-sm font-medium text-gray-900">Birth Date <em>*</em></label>
              <Calendar className="border rounded-full px-4 py-3" showIcon showButtonBar placeholder="mm/dd/yyyy" value={startDate} onChange={(e) => handleDateChange(e.value)} />
              
              {/* <DatePicker calendarIcon={<BsCalendar4Event size={20}/>}e onChange={handleDateChange} value={startDate} className="bg-gray-50 border-[1px] border-gray-300 text-gray-900 text-sm rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full py-3 px-4"/> */}
              {/* <input type="text" onKeyUp={handleInputChange} name="usernameOrPhone" id="usernameOrPhone" className="bg-gray-50 border-[1px] border-gray-300 text-gray-900 text-sm rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full p-4 px-4" placeholder="MMM / DD / YYYY" autoComplete='new-password' required /> */}
              {/* <small className="ml-3 text-red-500">{errors.birthdate}</small> */}
            </div>
            <div className="col-span-2 lg:col-span-2">
              <label labelfor="nationality" className="block mb-2 ml-3 text-sm font-medium text-gray-900">Nationality <em>*</em></label>
              <input type="text" onKeyUp={handleInputChange} name="nationality" id="nationality" className="bg-gray-50 border-[1px] border-gray-300 text-gray-900 text-sm rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full p-4 px-4" placeholder="Filipino" autoComplete='new-password' required />
              {/* <small className="ml-3 text-red-500">{errors.nationality}</small> */}
            </div>

            <div className="col-span-2 lg:col-span-4">
              <label labelfor="address" className="block mb-2 ml-3 text-sm font-medium text-gray-900">Address (This will be your billing address)<em>*</em></label>
              <input type="text" onKeyUp={handleInputChange} name="address" id="address" className="bg-gray-50 border-[1px] border-gray-300 text-gray-900 text-sm rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full p-4 px-4" placeholder="Your Address Here" autoComplete='new-password' required />
              {/* <small className="ml-3 text-red-500">{errors.address}</small> */}
            </div>
            <div className="col-span-2 lg:col-span-3">
              <label labelfor="city" className="block mb-2 ml-3 text-sm font-medium text-gray-900">City / Municipality <em>*</em></label>
              <input type="text" onKeyUp={handleInputChange} name="city" id="city" className="bg-gray-50 border-[1px] border-gray-300 text-gray-900 text-sm rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full p-4 px-4" placeholder="Metro Manila" autoComplete='new-password' required />
              {/* <small className="ml-3 text-red-500">{errors.city}</small> */}
            </div>
            <div className="col-span-2 lg:col-span-1">
              <label labelfor="zipcode" className="block mb-2 ml-3 text-sm font-medium text-gray-900">Zip Code <em>*</em></label>
              <input type="text" onKeyUp={handleInputChange} name="zipcode" id="zipcode" className="bg-gray-50 border-[1px] border-gray-300 text-gray-900 text-sm rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full p-4 px-4" placeholder="0 0 0 0" autoComplete='new-password' required />
              {/* <small className="ml-3 text-red-500">{errors.zipcode}</small> */}
            </div>

            
            <div className="col-span-2 lg:col-span-4">
              <label labelfor="email" className="block mb-2 ml-3 text-sm font-medium text-gray-900">Email Address <em>* {errors.email}</em></label>
              <input type="email" onKeyUp={handleInputChange} name="email" id="email" className="bg-gray-50 border-[1px] border-gray-300 text-gray-900 text-sm rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full p-4 px-4" placeholder="mailingaddress@email.com" autoComplete='new-password' required />
              
            </div>

            <div className="col-span-2 lg:col-span-4">
              <label labelfor="password" className="block mb-2 ml-3 text-sm font-medium text-gray-900">Password <em>* {errors.password}</em></label>
              <input type="password" onKeyUp={handleInputChange} name="password" id="password" className="bg-gray-50 border-[1px] border-gray-300 text-gray-900 text-sm rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full p-4 px-4" autoComplete='new-passwords' required />
            </div>
            <div className="col-span-2 lg:col-span-4">
              <label labelfor="confirmpassword" className="block mb-2 ml-3 text-sm font-medium text-gray-900">Confirm Password <em>* {errors.confirmpassword}</em></label>
              <input type="password" onKeyUp={handleInputChange} name="confirmpassword" id="confirmpassword" className="bg-gray-50 border-[1px] border-gray-300 text-gray-900 text-sm rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full p-4 px-4" autoComplete='new-passwords' required />
            </div>

            <div className="col-span-2 lg:col-span-4">
              <label labelfor="idtype" className="block mb-2 ml-3 text-sm font-medium text-gray-900">ID Submitted</label>
              <Select
                id={'idtype'}
                options={options} 
                isClearable={true} 
                onChange={(e) => handleSelectChange(e, 'idtype')}
                name="transaction_type"
                placeholder="Transaction Type"
                styles={{
                  control: (styles) => ({ 
                    ...styles, 
                    backgroundColor: 'transparent',
                    borderRadius: '9999px',
                    padding: 10,
                  }),
                  indicatorSeparator: (styles) => ({
                    ...styles,
                    width: 0,
                  }),
                }}
              />
              {/* <small className="ml-3 text-red-500">{errors.idtype}</small> */}
              {/* <input type="text" name="usernameOrPhone" id="usernameOrPhone" className="bg-gray-50 border-[1px] border-gray-300 text-gray-900 text-sm rounded-full focus-visible:ring-[1px] focus-visible:border-[1px] block w-full p-4 px-4" placeholder="+639 12345 6789" autoComplete='new-password' required /> */}
            </div>
            <div className="col-span-2 lg:col-span-4 flex flex-col">
              <label htmlFor="idurl" className="block mb-2 ml-3 text-sm font-medium text-gray-900">ID Preview</label>
              <div className="border-[1px] border-gray-300 rounded-lg h-[368px] flex justify-center p-4">
                <img alt={''} className="rounded-lg object-cover" src={imagePreview} />
              </div>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ display: 'none' }}
                id="idurl"
              />
              <label htmlFor="idurl" className="rounded-full text-center text-white px-8 py-2 bg-BrandGreen mt-4 cursor-pointer">
                Upload ID
              </label>
            </div>
            

            <div className="col-span-2 lg:col-span-8 mt-4">
              <h1 className="text-2xl font-bold text-sky-600 pb-3 border-b-[2px] border-BrandBlue">Security Questions</h1>
                
              <div className="accordion py-4">
                {
                  Array.from({ length: 3 }, (_, index) => {
                    var indexvalue = newUser[`sec_q${index + 1}`];
                    var defaultValue = securityQuestions.find(option => option.value === indexvalue);
                    const filteredSecurityQuestions = securityQuestions.filter(
                      question => !selectedQuestions.includes(question.value)
                    );
                    return (
                      <div className="accordion-item py-4 border-b" key={index}>
                        <div className="accordion-title">
                          <Select
                            options={filteredSecurityQuestions}
                            isClearable={true}
                            value={defaultValue}
                            defaultValue={defaultValue}
                            onChange={e => handleSelectChange(e, `sec_q${index + 1}`, true)}
                            name={`sec_q${index + 1}`}
                            id={`sec_q${index + 1}`}
                            placeholder={`Security Question #${index + 1} *`}
                            styles={{
                              control: styles => ({
                                ...styles,
                                backgroundColor: 'transparent',
                                border: 'none',
                                boxShadow: 'transparent !important',
                              }),
                              indicatorSeparator: styles => ({
                                ...styles,
                                width: 0,
                              }),
                            }}
                          />
                          {/* <small className="ml-3 text-red-500">{errors[`sec_q${index + 1}`]}</small> */}
                        </div>
                        <div className="accordion-content p-4">
                          <input
                            className="outline-none"
                            onChange={handleInputAnswerChange}
                            value={newUser[`sec_q${index + 1}_ans`]}
                            name={`sec_q${index + 1}_ans`}
                            type="password"
                            placeholder="Your answer here."
                          />
                        </div>
                        {/* <small className="ml-3 text-red-500">{errors[`sec_q${index + 1}_ans`]}</small> */}
                      </div>
                    );
                  })
                }
              </div>
                
            </div>
          </div>
          
          <div className="w-full flex justify-center items-center px-4 lg:p-0">
            <Checkbox pt={{input: {className: `border border-BrandBlue ${newUser.terms_and_conditions ? 'bg-BrandBlue': ''}` }}} inputId={'terms-and-conditions'} name="terms_and_conditions" checked={newUser.terms_and_conditions} onChange={handleCheckboxChange} />
            <label htmlFor={'terms-and-conditions'} className="ml-2 select-none">
              <p className="text-gray-500">
                By proceeding, you acknowledge your acceptance of our <a className="text-black" target="_blank" href="https://plancareph.com/terms-and-conditions/">Terms and Conditions</a> as well as our <a className="text-black" target="_blank" href="https://plancareph.com/refund_returns/">Refund Policy</a>.
              </p>
            </label>
          </div>

          <Button onClick={handleSubmit} className="bg-BrandGreen hover:bg-BrandBlue rounded-full text-white p-4 w-full font-medium text-sm" label="Submit" />
        </div>
      </div>
    </div>
  </>
  );
};

export default Register;