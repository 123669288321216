import React, { useContext, useEffect, useState } from "react";
import { BiHome, BiGroup, BiTransfer, BiCog, BiStore, BiLogOutCircle, BiWrench } from 'react-icons/bi';
import { GiTwoCoins } from "react-icons/gi";
import '../../Sidebar.css';
import { PiUsersThree } from 'react-icons/pi';
import { AuthContext } from "../../AuthContext";
import { UserContext } from "../../UserContext";
import { NavLink } from "react-router-dom";
import { CiMenuFries } from "react-icons/ci";
import { IoCloseOutline } from "react-icons/io5";
import { SidebarContext } from "../../contexts/SidebarContext";

const Sidebar = () => {

  const { logout } = useContext(AuthContext);
  const { user, modules } = useContext(UserContext);

  let timerCount = 3600; // 300 seconds = 5 minutes;
  const [timeRemaining, setTimeRemaining] = useState(timerCount); // Initial time remaining (5 seconds)

  useEffect(() => {
    let timer;
    // start the timer
    const startTimer = () => {
      timer = setInterval(() => {
        setTimeRemaining(prevTime => {
          if (prevTime === 0) {
            // Call logout function
            logout();
            console.log('User logged out due to inactivity');
            clearInterval(timer); // Stop the timer
            return 0;
          } else {
            // console.log("Time: ", prevTime-1);
            return prevTime - 1;
          }
        });
      }, 1000); // Update every second
    }

    //reset the timer
    const resetTimer = () => {
      clearInterval(timer);
      setTimeRemaining(timerCount); // Reset time remaining to timerCount value
      startTimer(); // Start the timer again
    }

    const handleMouseMove = () => {
      resetTimer();
    }

    document.addEventListener('mousemove', handleMouseMove);
    startTimer();

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      clearInterval(timer);
    };
  }, [logout]);

  const isMobile = (window.innerWidth <= 768);
  const { isOpen, setIsOpen } = useContext(SidebarContext);

  const available_modules = modules.filter(d => (d.name != 'View Team'));
  // console.log(available_modules);

  const getIcon = (moduleName) => {
    let icon = null;
    switch(moduleName){
      case 'Home': icon = <BiHome size={25}/>; break;
      case 'Teams': icon = <BiGroup size={25}/>; break;
      case 'Transactions': icon = <BiTransfer size={25}/>; break;
      case 'Users': icon = <PiUsersThree size={25}/>; break;
      case 'Settings': icon = <BiCog size={25}/>; break;
      case 'Shop': icon = <BiStore size={25}/>; break;
      case 'Roles': icon = <BiWrench size={25}/>; break;
      case 'Earnings': icon = <GiTwoCoins size={25}/>; break;
    }
    return icon;
  }

  const handleLogout = () => {
    logout();
  }

  useEffect(() => {
    setIsOpen(!isMobile);
  }, []);

  const handleOnLinkClick = () => {
    if(isMobile) {
      setIsOpen(false);
    }
  }
  
  return (
    <div className={`${!isOpen ? 'fixed -translate-x-full' : isMobile ? 'z-40 fixed' : 'relative'} h-full shadow-lg z-[100]`}>
      <div className={`w-64 h-full bg-white overflow-y-auto`}>
        <img alt={''} src="/Logo.png" className="mx-auto p-8" />
        <div className="flex items-center flex-col">
          <img alt={''} src={`${user.profile_url || '/person.png'}`} className="mb-2 w-[100px] h-[100px] object-cover rounded-full" />
          <p className="font-bold">{user.name}</p>
          <small>Referral Code { user.referral_code}</small>
        </div>
        <ul className="text-gray-700 mt-4">
          {
            available_modules && available_modules.map((m, i) => {
              return (
                <li key={i} className="block cursor-pointer px-2 hover:bg-BrandGreen hover:text-white flex">
                  <NavLink className="w-full flex items-center p-4 text-xl" to={'/' + m.url} onClick={handleOnLinkClick}>
                    {getIcon(m.name)}
                    <span className="ml-2">{m.name}</span>
                  </NavLink>
                </li>
              )
            })
          }

          <li className="block cursor-pointer px-2 hover:bg-BrandGreen hover:text-white flex" onClick={handleLogout}>
            <a className="w-full flex items-center p-4 text-xl" href="/#/">
              <BiLogOutCircle size={25}/>
              <span className="ml-2">Logout</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="absolute top-0 right-[-40px] shadow-lg rounded-r-lg inline-block p-3 bg-white text-black cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
        {
          !isOpen ? <CiMenuFries/> : <IoCloseOutline/>
        }
      </div>
    </div>
  )
}

export default Sidebar;