import React, { useRef, useState ,useContext, useEffect} from 'react';
import therest from "../../therest";
import BreadCrumb from '../shared/BreadCrumb';
import { AuthContext } from "../../AuthContext";
import { BsStars } from 'react-icons/bs';
import { BiGroup, BiSolidShoppingBags, BiChevronDown } from 'react-icons/bi';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tag } from "primereact/tag";
import { NavLink } from 'react-router-dom';
const Dashboard = () => {
  const dt = useRef(null);
  const { apiToken } = useContext(AuthContext);
  const [totalEarnings, setTotalEarnings] = useState(0.00);
  const [availEarnings, setAvailEarnings] = useState(0.00);
  const [totalusers, setTotalUsers] = useState(0.00);
  
  const fetchCardsData = () => {
    therest.get('/teams', apiToken).then((response) => {
      if(response.status){
        console.log(response)
        setTotalUsers(response.count);
      } else {
        // Notification('Failed', 'There was an unexpected error.', 'danger');
      }
    });
  }
  
  const getEarnings = () => {
    therest.get('/transactions/earnings', apiToken).then((response) => {
      console.log(response);
      // setEarnings(response.earnings);
      setAvailEarnings(formatNumber(response.cleared));
      setTotalEarnings(formatNumber(response.total_earnings));
    });
  }
  const formatNumber = (rowData) => {
    return Number(rowData).toFixed(0);
  }
  
  useEffect(() => {
    getEarnings();
    fetchCardsData();
  }, []);
  return (
    <>
      <BreadCrumb links={[{title: 'Dashboard', link: '/'}]}/>
      <div className="w-full mt-8">
        <div className='grid md:grid-cols-3 gap-8'>
          <NavLink to={'/earnings'} className='px-4 pt-4 pb-10 w-full bg-white rounded-lg flex-col items-center'>
            <div className="flex-col justify-start items-center gap-6 flex">
              <div className="justify-between items-start inline-flex w-full">
                <div className="text-zinc-800 text-base font-bold leading-none">Total Accumulated Points</div>
                <div className="justify-end items-start gap-2 flex">
                  <div className="text-right text-zinc-800 text-sm font-normal leading-none">As of date</div>
                  <div className="text-right text-green-500 text-sm font-normal leading-none"><BiChevronDown /></div>
                </div>
              </div>
              <div className="flex-col justify-start items-center gap-2 flex">
                <div className="self-stretch justify-center items-start gap-2 inline-flex">
                  <div className="text-center text-pink-400 text-[40px] font-normal leading-10"><BsStars/></div>
                  <div className="text-center text-pink-400 text-[40px] font-bold leading-10">{totalEarnings}</div>
                </div>
                <div className="text-center text-neutral-700 text-2xl font-semibold leading-normal">points</div>
              </div>
            </div>
          </NavLink>

          <NavLink to={'/earnings'} className='px-4 pt-4 pb-10 w-full bg-white rounded-lg flex-col items-center'>
            <div className="flex-col justify-start items-center gap-6 flex">
              <div className="justify-between items-start inline-flex w-full">
                <div className="text-zinc-800 text-base font-bold leading-none">Total Available Points</div>
                <div className="justify-end items-start gap-2 flex">
                  <div className="text-right text-zinc-800 text-sm font-normal leading-none">As of date</div>
                  <div className="text-right text-green-500 text-sm font-normal leading-none"><BiChevronDown /></div>
                </div>
              </div>
              <div className="flex-col justify-start items-center gap-2 flex">
                <div className="self-stretch justify-center items-start gap-2 inline-flex">
                  <div className="text-center text-yellow-400 text-[40px] font-normal leading-10"><BsStars/></div>
                  <div className="text-center text-yellow-400 text-[40px] font-bold leading-10">{availEarnings}</div>
                </div>
                <div className="text-center text-neutral-700 text-2xl font-semibold leading-normal">points</div>
              </div>
            </div>
          </NavLink>

          <NavLink to={'/users'} className='px-4 pt-4 pb-10 w-full bg-white rounded-lg flex-col items-center'>
            <div className="flex-col justify-start items-center gap-6 flex">
              <div className="justify-between items-start inline-flex w-full">
                <div className="text-zinc-800 text-base font-bold leading-none">Total Active Members</div>
                <div className="justify-end items-start gap-2 flex">
                  <div className="text-right text-zinc-800 text-sm font-normal leading-none">As of date</div>
                  <div className="text-right text-green-500 text-sm font-normal leading-none"><BiChevronDown /></div>
                </div>
              </div>
              <div className="flex-col justify-start items-center gap-2 flex">
                <div className="self-stretch justify-center items-start gap-2 inline-flex">
                  <div className="text-center text-emerald-400 text-[40px] font-normal leading-10"><BiGroup/></div>
                  <div className="text-center text-emerald-400 text-[40px] font-bold leading-10">{totalusers}</div>
                </div>
                <div className="text-center text-neutral-700 text-2xl font-semibold leading-normal">members</div>
              </div>
            </div>
          </NavLink>

        
        </div> 
      </div>

      <div className="w-full min-h-[50%] relative bg-white rounded-lg mt-8">
        <div className="w-full h-4 justify-between items-start inline-flex p-4">
          <div className="text-zinc-800 text-base font-bold leading-none">Pending Members</div>
          <div className="justify-end items-start gap-2 flex">
            <div className="text-right text-zinc-800 text-sm font-normal leading-none">As of date</div>
            <div className="text-right text-green-500 text-sm font-normal leading-none"><BiChevronDown /></div>
          </div>
        </div>
        <div className='w-full p-4 mt-8'>
            {/* <DataTable ref={dt} header={header} className="bg-transparent" value={users.filter((d,i) => ( selectedStatusOption == null || selectedStatusOption == '' ? true : (d.status == (selectedStatusOption && selectedStatusOption.value) || '') ) )} size={'medium'} paginator rows={10} rowsPerPageOptions={[10, 50, 100]} tableStyle={{ minWidth: '50rem' }} stripedRows>
       
              <Column headerClassName="border-b" field="email" sortable header="Email" />
              <Column headerClassName="border-b" field="referral_code" sortable header="My Referral Code (User ID)" />
              <Column headerClassName="border-b" field="referredby" sortable header="Referred By" />
              <Column headerClassName="border-b" field="status" sortable header="Status" body={renderStatus} />
              <Column headerClassName="border-b" header="" body={renderPaymentButton} />

            </DataTable> */}
        </div>
      </div>
    </>
  );
};

export default Dashboard;