import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import BreadCrumb from "../shared/BreadCrumb";
import { BiArrowBack, BiSolidStar } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import therest from "../../therest";
import moment from "moment";
import TeamTree from "../shared/TeamTree";
import Modals from "../modals/Modals";
import { Toast } from "primereact/toast";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { OrganizationChart } from 'primereact/organizationchart';
import { Avatar } from "primereact/avatar";

const Team = () => {
  const { apiToken, apiHeader } = useContext(AuthContext);
  const { id } = useParams();
  const toast = useRef(null);
  const [member, setMember] = useState([]);
  const [treeView, setTreeView] = useState(false);
  const getMember = async (id) => {
    try {
      const response = await therest.get('/member/' + id, apiToken);
      if (response.status) {
        setMember(response.member);
      } else {
        toast.current.show({ severity: 'error', summary: 'Failed!', detail: 'There was an unexpected error.' });
      }
    } catch (error) {
      console.error('Error fetching member:', error);
    }
  };

  const convertToTreeData = (response) => {
    const team = response.team;
   
    const createNode = (node, type = 'person', className) => ({
      expanded: true,
      type: type,
      className: className,
      data: node,
      children: []
    });

    const teamNode = createNode({

      id: team.id,
      img: team.profile_url || '/person.png',
      name: team.name,
      members_count: team.members_count,
      referral_code: team.referral_code,
      img: team.profile_url || '/person.png',
      status:team.status,
    }, 'person', `${team.status==5? 'bg-BrandGrey': (team.members_count > 2 ? 'bg-BrandGreen':'bg-BrandRed')} text-white rounded-[15px] p-2`);
    
    const addChildren = (parentNode, children) => {
      parentNode.children = children.map((child, index) => {
        const childNode = createNode({
          id: child.id,
          img: child.profile_url || '/person.png',
          name: child.name,
          members_count: child.members_count,
          referral_code: child.referral_code,
          img: child.profile_url || '/person.png',
          status: child.status,
        }, 'person', `${child.status==5? 'bg-BrandGrey': (child.members_count > 2 ? 'bg-BrandGreen':'bg-BrandRed')} text-white rounded-[15px] p-2`);
        console.log("child data " + child.status)
        if (child.members && child.members.length > 0) {
          addChildren(childNode, child.members);
        }
        return childNode;
      });
    };

    addChildren(teamNode, team.members);

    // Return the data in the desired format
    return [teamNode];
  }

  const getMemberNodes = (teamNodeID, members, indexCounter = 1) => {
    return members.map((member, index) => ({
      id: indexCounter + (index + 1),
      ref_id: member.id,
      pid: teamNodeID,
      gender: member.cleared == 1 ? 'male' : 'female',
      referral_code: member.referral_code, 
      name: member.name,
      tags: [member.id, (member.cleared == 1 ? 'male' : 'female')],
      img: member.profile_url || '/person.png',
      members_count: member.members_count,
    }));
  }

  const appendMembersToParentNode = (parentID, newTeam) => {
    const createNode = (node, type = 'person', className) => ({
      expanded: true,
      type: type,
      className: className,
      data: node,
      children: []
    });
  
    const addChildren = (parentNode, children) => {
      parentNode.children = children.map((child, index) => {
        const childNode = createNode({
          id: child.id,
          img: child.profile_url || '/person.png',
          name: child.name,
          members_count: child.members_count,
          referral_code: child.referral_code,
          status: child.status,
        }, 'person', `${child.status==5? 'bg-BrandGrey': (child.members_count > 2 ? 'bg-BrandGreen' :'bg-BrandRed')} text-white rounded-[15px] p-2`);
  
        if (child.members && child.members.length > 0) {
          addChildren(childNode, child.members);
        }
        return childNode;
      });
    };
  
    const findNodeByID = (nodes, id) => {
      for (const node of nodes) {
        if (node.data.id === id) {
          return node;
        } else if (node.children && node.children.length > 0) {
          const foundNode = findNodeByID(node.children, id);
          if (foundNode) return foundNode;
        }
      }
      return null;
    };
  
    setTreeData(prevTreeData => {
      const newTreeData = [...prevTreeData];
      const parentNode = findNodeByID(newTreeData, parentID);
      console.log(parentNode);
      if (parentNode) {
        const newMembers = newTeam.members.filter(member =>
          !parentNode.children.some(child => child.data.id === member.id)
        );
        addChildren(parentNode, newMembers);
      }
  
      return newTreeData;
    });
  };

  const [treeData, setTreeData] = useState();
  const getTreeData = async (id) => {
    try {
      const response = await therest.get('/team/' + id, apiToken, apiHeader);
      if (response.status) {
        setTreeData(convertToTreeData(response));
        console.log('The data', convertToTreeData(response));
      } else {
        toast.current.show({ severity: 'error', summary: 'Failed!', detail: 'There was an unexpected error.' });
      }
    } catch (error) {
      console.error('Error fetching tree data:', error);
    }
  };

  useEffect(() => {
    setTreeView(localStorage.getItem('treeviewActive'));

    getMember(id);
    getTreeData(id);
  }, [id]);

  const handleTreeViewClick = async (userID) => {
    // const treeParentID = treeData.nodes.filter(node => node.ref_id == userID).map((node) => node.id)[0]
    // console.log(userID, treeParentID);
    try {
      const response = await therest.get('/team/' + userID, apiToken);
      if (response.status) {
        appendMembersToParentNode(userID, response.team);
      } else {
        toast.current.show({ severity: 'error', summary: 'Failed!', detail: 'There was an unexpected error.' });
      }
    } catch (error) {
      console.error('Error fetching tree data:', error);
    }
  };

  const handleBackButtonClick = () => {
    var clickhistory = JSON.parse(localStorage.getItem('clickHistory'));
    const lastId = clickhistory.pop();
    const idToCall = clickhistory.pop();
    
    if (idToCall !== id) { 
      localStorage.setItem('clickHistory', JSON.stringify(clickhistory));
    }
    getTreeData(idToCall);
  };
    
  const [viewUserModal, setViewUserModal] = useState(false);
  const [modalUserData, setModalUserData] = useState();
  const getUserData = (id) => {
    therest.get('/user/' + id, apiToken).then((response) => {
      if(response.status){
        setModalUserData(response.user);
      } else {
        setViewUserModal(false);
      }
    });
  }
  const onViewProfile = (id)=>{
    //get user data and show in modal or page
    setViewUserModal(true);
    getUserData(id);
  }
  const viewUserDataOnHide = (isTableUpdate) => {
    setViewUserModal(false);
    setModalUserData();

    if(isTableUpdate){
      // fetchData();
      getTreeData(id);
    }
  }

  const userModalTriggerMessage = (status, message) => {
    if(status){
      toast.current.show({ severity: 'success', summary: 'Success', detail: message });
    } else {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: message });
    }
  }

  const viewUserDataOnSubmit = (data) => {
    therest.post('/user/update-user-referrer', data, apiToken).then((response) => {
      if(response.status){
        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message });
      } else {
        toast.current.show({ severity: 'error', summary: 'Update Failed!', detail: 'Referred By is not valid.' });
      }
    });
  }

  const nodeTemplate = (node) => {
    if (node.type === 'person') {
      return (
        <div className="flex flex-col relative">
          <div className="flex flex-col items-center w-[200px]">
            <img alt={node.data.name} src={node.data.img} className="mb-2 w-20 h-20 rounded-full" />
            <span>{node.data.name}</span>
            <small>{node.data.referral_code}</small>
            {/* <span>{node.data.title}</span> */}
          </div>
          <div className="absolute bottom-0 right-0 text-[10px] bg-black border-white border rounded-full min-h-[25px] min-w-[25px] p-[4px]">
            {node.data.members_count}
          </div>
        </div>
      );
    }

    return node.label;
  };


  return (
    <>
      <Toast ref={toast} />
      <BreadCrumb links={[{title: 'Teams', link: '/teams'}, {title: 'View Member', link: '/team/' + id}]}/>
      {
        !treeView && (
          <div className="w-full min-h-[50%] relative bg-white rounded-lg lg:mt-8 mt-4">
            <div className="w-full gap-4 flex items-center lg:p-8 p-4">
              <NavLink to={'/teams'}>
                <BiArrowBack className="text-green-500 text-3xl"/>
              </NavLink>
              <h1 className="lg:text-4xl text-xl font-bold text-sky-600">View Profile</h1>
            </div>
            <div className="w-full flex gap-8 lg:p-8 p-4">
              <div>
                <img alt={member && member.name} className='w-[100px] h-[100px] object-cover rounded-full' height="50" src={member && member.profile_url || 'https://placehold.co/100'}/> 
              </div>
              <div className="flex flex-col justify-center">
                <h1 className="font-bold">{member && member.name}</h1>
                <p>Date joined: {member && moment(member.created_at).format('YYYY-MM-DD hh:mm A')}</p>
              </div>
            </div>
            <div className="w-full p-4 pb-8">
              <div className="grid lg:grid-cols-12 grid-cols-2 gap-8">
                <div className="hidden lg:block"></div>
                <div className="lg:col-span-11 flex flex-col">
                  <label className="font-bold">Address</label>
                  <span>{member && member.address}</span>
                </div>
                <div className="hidden lg:block"></div>
                <div className="lg:col-span-2 flex flex-col">
                  <label className="font-bold">City</label>
                  <span>{member && member.city}</span>
                </div>
                <div className="lg:col-span-2 flex flex-col">
                  <label className="font-bold">Province</label>
                  <span>---</span>
                </div>
                <div className="lg:col-span-7 flex flex-col">
                  <label className="font-bold">Zip</label>
                  <span>{member && member.zipcode}</span>
                </div>
                <div className="hidden lg:block"></div>
                <div className="lg:col-span-11 flex flex-col">
                  <label className="font-bold">Email</label>
                  <span>{member && member.email}</span>
                </div>
                <div className="hidden lg:block"></div>
                {/* <div className="col-span-11 flex flex-col">
                  <label className="font-bold">Top-level</label>
                  <span>Leah Bryant</span>
                </div> */}
              </div>
            </div>
          </div>
        )
      }
      <div className="mt-4">&nbsp;</div>
      {
        treeView && (
          // <div className="mt-5 h-full">
            <TransformWrapper
              initialScale={0.7}
              initialPositionX={100}
              initialPositionY={0}
              centerOnInit={true}
              centerZoomedOut={true}
              smooth={true}
              limitToBounds={true}
              minScale={0.1}
              maxScale={1}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <>
                  <TransformComponent wrapperStyle={{'width': '-webkit-fill-available', height: '90%'}} wrapperClass="bg-white rounded-lg mt-4">
                    {
                      treeData && 
                      <OrganizationChart 
                        value={treeData} 
                        nodeTemplate={nodeTemplate}
                        selectionMode="single"
                        onSelectionChange={(e) => { 
                          const { children, data } = e.data;
                          const { members_count, id } = data;
                          
                          if (children.length < 1 && members_count !== 0) {
                            handleTreeViewClick(id);
                          }
                        }}
                        pt={{
                          lineLeft: { className: 'border-BrandBlue'},
                          lineRight: { className: 'border-BrandBlue'},
                          lineDown: { className: 'bg-BrandBlue'},
                        }}
                      />
                    }
                  </TransformComponent>
                </>
              )}
              
            </TransformWrapper>
          // {/* </div> */}
        )
      }
      <Modals type='user' data={modalUserData} open={viewUserModal} onSubmit={viewUserDataOnSubmit} onHide={viewUserDataOnHide} triggerMessage={userModalTriggerMessage}/>
    </>
  )
}

export default Team;