const apiUrl = process.env.REACT_APP_API_URL;

const sendRequest = async (url, method, token, data) => {
  const options = {
    method,
    mode: 'cors',
    headers: {},
  };

  if (data) {
    if (data instanceof FormData) {
      // Do not set Content-Type for FormData; let the browser handle it
      options.body = data;
    } else {
      options.headers['Content-Type'] = 'application/json';
      options.body = JSON.stringify(data);
    }
  }

  if (token) {
    options.headers['Authorization'] = 'Bearer ' + token;
  }

  try {
    const response = await fetch(apiUrl + url, options);
    if (!response.ok) {
      console.error('Network response was not ok:', response.statusText);
      return null;
    }
    return await response.json();
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
    return null;
  }
};

const therest = {
  get: (url, token, params = {}) => sendRequest(url, 'GET', token),

  post: (url, data, token) => sendRequest(url, 'POST', token, data),

  put: (url, data, token) => sendRequest(url, 'PUT', token, data),

  delete: (url, token) => sendRequest(url, 'DELETE', token),
};

export default therest;
