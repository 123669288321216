import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../UserContext";
import BreadCrumb from "../shared/BreadCrumb";
import therest from "../../therest";
import { AuthContext } from "../../AuthContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
import { BsPencilSquare,BsDownload, BsCheckCircleFill,BsFillTrash3Fill, BsInfoCircle,BsCheck,BsFillXCircleFill } from "react-icons/bs";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { FilterMatchMode } from "primereact/api";
import { Chip } from "primereact/chip";
import { Tooltip } from "primereact/tooltip";
import { CiRead, CiShoppingCart } from "react-icons/ci";
import { Image } from "primereact/image";
import { MdOutlineSearch } from "react-icons/md";
import { InputNumber } from "primereact/inputnumber";
import { TabPanel,TabView } from "primereact/tabview";
import { LoadingContext } from "../../contexts/LoadingContext";
import { Carousel } from "primereact/carousel";
import { Galleria } from "primereact/galleria";

const Shop = () => { 
  const toast = useRef(null);
  const { user } = useContext(UserContext);
  const { apiToken } = useContext(AuthContext);
  // const [totalEarnings, setTotalEarnings] = useState(0.00);
  const [withdrawablepoint, setWithdrawablePoint] = useState(0.00);
  // const [earnings, setEarnings] = useState();
  const [products, setProducts] = useState();
  const { setIsShowLoading } = useContext(LoadingContext);

  const getProducts = () => {
    therest.get('/products', apiToken).then((response) => {
      console.log(response);
      setProducts(response.products);
    });
  }

  const getEarnings = () => {
    therest.get('/transactions/earnings', apiToken).then((response) => {
      console.log(response);
      // setEarnings(response.earnings);
      // setTotalEarnings(formatNumber(response.total_earnings));
      setWithdrawablePoint(formatNumber(response.total_withdrawable));
      // console.log(withdrawablepoint>5000);
    });
  }
  const formatNumber = (rowData) => {
    return Number(rowData.amount || rowData).toFixed(2);
   
  }
  const product = {
    name: '',
    description: '',
    photo_url: '',
    photo_data: '',
    price: 0.00,
  }
  const [updatedProduct, setUpdatedProduct] = useState(product);

  const [isVisible, setIsVisible] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [purchaseTab, setpurchaseTabLoading] = useState(false);
  const showModal = (isupdate = false, data = null) => {
    setUpdatedProduct(product);
    if(isupdate && data){
      setIsUpdate(true);
      setUpdatedProduct({
        ...updatedProduct, 
        id: data.id,
        name: data.name,
        description: data.description,
        photo_url: data.photo_url,
        price: data.price,
      });
    }

    setIsVisible(true);
  }

  const onModalClose = () => {
    setIsVisible(false);
    setIsUpdate(false);
    setUpdatedProduct(product);
    setProductImage({data: '', name: ''});
    setFiles([]);
  }

  const [productImage, setProductImage] = useState({data: '', name: ''});
  const handleProductImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProductImage({ ...productImage, data: reader.result, name: file.name});
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    setUpdatedProduct({...updatedProduct, [e.target.name]: e.target.value});
  }

  const [files, setFiles] = useState([]);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles(prevFiles => [...prevFiles, ...newFiles]);
  };

  const handleRemoveFile = (index) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
  };

  const handleRemovePhoto = (photourl) => {
    const updatedPhotosArray = updatedProduct.photo_url.split(',')
      .filter(url => url !== photourl)
      .join(',');

    setUpdatedProduct(prevState => ({
      ...prevState,
      photo_url: updatedPhotosArray
    }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append('name', updatedProduct.name);
    formData.append('description', updatedProduct.description);
    formData.append('price', updatedProduct.price);

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        formData.append('files[]', files[i]);
      }
    }

    therest.post('/add/new-product', formData, apiToken).then((response) => {
      if (response.status) {
        getProducts();
        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message });
      } else {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: response.message });
      }
      onModalClose();
    }).catch((error) => {
      console.error('Error adding product:', error);
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'An error occurred while adding the product.' });
    });
  }

  const handleProductUpdate = () => {

    const formData = new FormData();

    formData.append('id', updatedProduct.id);
    formData.append('name', updatedProduct.name);
    formData.append('description', updatedProduct.description);
    formData.append('price', updatedProduct.price);

    if (updatedProduct.photo_url) {
      formData.append('photo_url', updatedProduct.photo_url);
    }
    
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        formData.append('files[]', files[i]);
      }
    }

    therest.post(`/product/update-details/${updatedProduct.id}`, formData, apiToken).then((response) => {
      if(response.status){
        getProducts();
        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message });
      } else {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: response.message });
      }
      onModalClose();
    });
  }

  const footerContent = (
    <div className="flex gap-2 justify-end">
      <button className="w-[150px] border-BrandGreen border text-BrandGreen px-8 py-4 rounded-full" onClick={() => onModalClose()}>Cancel</button>
      {
        !isUpdate && (
          <button className="w-[150px] bg-BrandGreen text-white px-8 py-4 rounded-full" onClick={handleSubmit}>Save</button>
        ) 
      }
      {
        isUpdate && (
          <button className="w-[150px] bg-BrandGreen text-white px-8 py-4 rounded-full" onClick={handleProductUpdate}>Update</button>
        ) 
      }
    </div>
  );

  const handleSwitchChange = (e, type, id) => {
    
    var data = {
      [type]: e ? 1 : 0
    }

    therest.put('/product/update-status/' + id, data, apiToken).then((response) => {
      if(response.status){
        getProducts();
        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message });
      } else {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: response.message });
      }
    });
  }

  const tableSwitchIsActive = (data) => {
    return <InputSwitch onChange={(e) => handleSwitchChange(e.value, 'is_active', data.id)} checked={data.is_active == 1} />
  } 
  const tableSwitchIsShopActive = (data) => {
    return <InputSwitch onChange={(e) => handleSwitchChange(e.value, 'is_shop_active', data.id)} checked={data.is_shop_active == 1} />
  } 

  const [viewProduct, setViewProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [qtyToBuy, setQtyToBuy] = useState(1);
  const [prodpurchased, setProdPurchased] = useState();
  const handleViewProductModalClose = () => {
    setViewProduct(false);
    setSelectedProduct();
    setQtyToBuy(1);

  }

  const handleBuyProduct = () => {
    console.log(selectedProduct);
    setIsShowLoading(true);
    if((selectedProduct.price*qtyToBuy) > withdrawablepoint){
      toast.current.show({ severity: 'error', summary: 'Failed',detail:"Order exeeds quantity allowed" });
      setIsShowLoading(false);
    }else{
      therest.post('/transaction/buy/product/'+qtyToBuy, selectedProduct, apiToken).then((response) => {
        setIsShowLoading(false);
        if(response.status){
          toast.current.show({ severity: 'success', summary: 'Success', detail: response.message });
          getEarnings();
        } else {
          toast.current.show({ severity: 'error', summary: 'Failed', detail: response.message });
        }
        handleViewProductModalClose();
      });
    }
  }

  const viewProductFooter = (
    <div className="flex gap-2 justify-end">
      {/* <button className="w-[150px] border-red-500 border text-red-500 hover:text-white hover:bg-red-500 px-8 py-4 rounded-full" onClick={() => setViewProduct(false)}>Close</button> */}
      <Button className="w-[150px] border-BrandGreen border text-BrandGreen hover:text-white hover:bg-BrandGreen px-8 py-4 rounded-full" onClick={() => handleBuyProduct()} label={'Buy Now'} />
    </div>
  );
  
  
  const renderBuyButton = (data) => {
    
    // console.log(data);
    return (
      <>
        <div className="flex justify-start gap-1">
          {/* <Tooltip target=".view-product" />
          <CiRead onClick={() => setViewProduct(true)} data-pr-tooltip="View Product" data-pr-position="top" size={40} className="view-product text-BrandBlue hover:bg-BrandBlue hover:text-white hover:rounded-full p-1" /> */}
          <Tooltip target=".cart" />
          <CiShoppingCart onClick={() => {
            setViewProduct(true);
            setSelectedProduct(data);
          }} data-pr-tooltip="Buy Item" data-pr-position="top" size={40} className="cart text-BrandGreen hover:bg-BrandBlue hover:text-white hover:rounded-full p-1"/>
         
        </div>
      </>
    )
  }
  const tableEditAction = (data) => {
    return (
      <>
        <div className="flex justify-end gap-2">
          <BsPencilSquare className="text-BrandGreen cursor-pointer" onClick={() => showModal(true, data)} />
          <button onClick={(e) => confirmDelete(e, data.id)}><BsFillTrash3Fill className="text-red-500 cursor-pointer" /></button>
        </div>
      </>
    )
  }

  var deleteId = 0; 
  const accept = () => {
    therest.delete('/product/delete/' + deleteId, apiToken).then((response) => {
      if(response.status){
        getProducts();
        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message });
      } else {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: response.message });
      }
    });
  };

  const reject = () => {
    toast.current.show({ severity: 'warn', summary: 'Status', detail: 'Action cancelled!', life: 3000 });
  };

  const confirmDelete = (event, product_id) => {
    deleteId = product_id;
    confirmPopup({
      target: event.currentTarget,
      message: 'Do you want to delete this product?',
      icon: <BsInfoCircle/>,
      acceptClassName: 'ml-3 px-4 py-2 bg-red-500 text-white outline-none p-button-danger',
      rejectClassName: 'px-4 py-2',
      accept,
      reject
    });  
  };

  useEffect(() => {
    getProducts();
    getEarnings();
  }, []);

  const dt = useRef(null); 

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });

  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  
  const getPurchasedproducts = () => {
    setpurchaseTabLoading(true);
    therest.get('/productpurchase/purchases', apiToken).then((response) => {
      console.log(response)
      if(response.status){
        setProdPurchased(response.purchase);
      }
      setpurchaseTabLoading(false);
    });
 
  }

  const onBeforeTabChanges = (e) => {
    const { index, originalEvent } = e;
    if(index == 1 ){ 
      getPurchasedproducts();
    }
  }

  const getTotalAmount =(rowData)=>{
    return rowData.quantity * rowData.product.price;
  }

  const getStatusValue =(rowData)=>{
    switch (rowData.status){
      case 1:
        return "Complete";
      case 2:
        return "Cancelled";
      default:
        return "Pending";
    }
  }
  const updatepurchase = (rowData,status)=>{
    setIsShowLoading(true);
    rowData.status=status;
    console.log(rowData);
    therest.post('/productpurchase/update/', rowData, apiToken).then((response) => {
      setIsShowLoading(false);
      if(response.status){
        toast.current.show({ severity: 'success', summary: 'Success', detail: response.message });
        getPurchasedproducts();
      } else {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: response.message });
      }
    });
  }
  const renderButtons = (rowData)=>{
    if(rowData.status!=2){
      return (
      <>
        <div className="flex justify-start gap-2">
          <Tooltip target=".approve-order" />
          <BsCheckCircleFill data-pr-tooltip="Approve" className="approve-order text-BrandGreen cursor-pointer" onClick={() => updatepurchase(rowData,1)} />
          <Tooltip target=".reject-order" />
          <BsFillXCircleFill data-pr-tooltip="Cancel" className="reject-order text-red-500 cursor-pointer" onClick={() => updatepurchase(rowData,2)}/>
        </div>
      </>
      )
    }else{
      return "";
    }
    
    
  }
  const header = (
    <div className="flex justify-between -mx-4">
      <span className="p-input-icon-left flex items-center">
        <i className="pi pi-search" />
        <InputText className="w-full ring-0 border rounded-full pl-10 pr-4 py-3 font-normal" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
      </span>
      <Tooltip target=".downlaod-csv" />
      <Button className='downlaod-csv bg-BrandBlue text-white px-4 py-2' type="button" icon="pi pi-download" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
    </div>
  );

  const photoTemplate = (photo) => {
    return <div className="p-5 relative">
      <Image src={photo} alt="Image" width="250" preview className="rounded-lg border"/>
      <i onClick={() => handleRemovePhoto(photo)} className="pi pi-trash absolute top-2 right-2 text-red-500 bg-transparent cursor-pointer" ></i>
    </div>
  }

  const itemTemplate = (item) => {
    return <img src={item} alt={item} style={{ width: '60%' }} />
  }

  const thumbnailTemplate = (item) => {
    return <img src={item} alt={item} style={{height: '100px'}} />
  }

  const [activeIndex, setActiveIndex] = useState(1);

  const renderThumbnail = (data) => {
    const value = data?.photo_url ? data.photo_url.split(',') : ['/empty_product.png'];
    const image = value?.[0];  // Safely access the first element
    return image ? (
      <Image src={image} zoomSrc={image} alt="Image" width="80" height="60" preview />
    ) : (
      <span></span>  // Fallback if image is not available
    );
  }

  return (
    <> 
      <ConfirmPopup />
      <Toast ref={toast} />
      <BreadCrumb links={[{title: 'Shop', link: '/shop'}]}/>
      <div className="w-full min-h-[50%] relative bg-white rounded-lg mt-8">
        <div className="w-full gap-4 flex items-center p-4 lg:p-8 pb-0 justify-between">
          <h1 className="lg:text-4xl text-xl font-bold text-sky-600">Shop</h1>
        </div>
        <div className="lg:px-8 p-4">
          <div className="flex items-center gap-8">
            <div className="flex items-center gap-4">
              <span>Accumulated Points:</span>
              <Chip label={withdrawablepoint} />
            </div>
          </div>
        </div>
        <div className="mb-5 px-4 lg:px-8 pb-8">  
        <TabView panelContainerClassName="px-4 border" onBeforeTabChange={onBeforeTabChanges}>
          <TabPanel
           pt={{
            headerAction: ({ parent }) => ({
              className: parent.state.activeIndex === 0 ? 'bg-BrandGreen text-white' : ''
            })
            }} key={'1-1'} header={'Products'}
          >
          {
            user.is_admin == 1 && (
              <>
              <div className="w-full">
                <button className="bg-BrandGreen text-white px-8 py-4 rounded-full" onClick={showModal}>Add Product</button>
              </div>

              <div className="py-4">
                <div className="">
                  <DataTable ref={dt} filters={filters} header={header} className="bg-transparent" value={products} size={'medium'} paginator rows={10} rowsPerPageOptions={[10, 50, 100]} tableStyle={{ minWidth: '50rem' }} stripedRows>
                    <Column headerClassName="border-b" field="photo_url" header="" body={renderThumbnail} />
                    <Column headerClassName="border-b" field="id" sortable header="ID" />
                    <Column headerClassName="border-b" field="name" sortable header="Product Name" />
                    <Column headerClassName="border-b" field="description" sortable header="Description" />
                    <Column headerClassName="border-b" field="price" sortable header="Price" />
                    <Column headerClassName="border-b" header="Active" body={tableSwitchIsActive}/>
                    <Column headerClassName="border-b" header="Shop" body={tableSwitchIsShopActive} />
                    <Column headerClassName="border-b" header="" body={tableEditAction} />
                  </DataTable>
                </div>
              </div>

              <Dialog header={`${isUpdate ? 'Update' : 'Add' } Product`} visible={isVisible} position={'center'} className="w-[95%] lg:w-1/3" onHide={() => onModalClose()} footer={footerContent} draggable={false} resizable={false}>
                <form onSubmit={handleSubmit}>
                  <div className="grid lg:grid-cols-2 gap-4 mb-4">
                    <div className="lg:col-span-1 flex flex-col">
                      <label htmlFor="name">Product Name</label>
                      <InputText id="name"  name="name" value={updatedProduct.name} onChange={handleInputChange} className="border rounded-full px-4 py-3" />
                    </div>
                    <div className="lg:col-span-1 flex flex-col">
                      <label htmlFor="price">Price</label>
                      <InputText keyfilter={'money'} name="price" value={updatedProduct.price} onChange={handleInputChange} id="price" className="border rounded-full px-4 py-3" />
                    </div>
                    <div className="lg:col-span-2 flex flex-col">
                      <label htmlFor="description">Product Description</label>
                      <InputText id="description" name="description" value={updatedProduct.description} onChange={handleInputChange} className="border rounded-full px-4 py-3" />
                    </div>
                    <div className="lg:col-span-2">
                      <label htmlFor="amount">Product Image</label>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        id="proof_of_payment"
                        multiple
                      />
                      <p className="mt-4 text-center flex flex-col gap-1">
                        {
                          isUpdate && updatedProduct.photo_url?.split(',').length > 0 && <Carousel value={updatedProduct.photo_url.split(',')} numVisible={3} numScroll={1} circular itemTemplate={photoTemplate} />
                        }
                        
                        {files && files.map((file, index) => (
                          <div key={index} className="flex justify-between items-center">
                            <p>{file.name}</p>
                            <i onClick={() => handleRemoveFile(index)} className="pi pi-trash bg-transparent text-red-500 cursor-pointer"></i>
                          </div>
                        ))}
                      </p>
                      <label htmlFor="proof_of_payment" className="block border text-center border-BrandGreen text-BrandGreen px-4 py-2 w-full mt-4 rounded-full hover:bg-BrandGreen hover:text-white cursor-pointer" >Browse File</label>
                    </div>
                  </div>
                </form>
              </Dialog>
              </>
            )
          }
          {
            user.is_admin == 0 && (
              <>
              <div className="py-4">
                <div className="">     
                  <DataTable ref={dt} header={header} className="bg-transparent" value={products} size={'medium'} paginator rows={10} rowsPerPageOptions={[10, 50, 100]} tableStyle={{ minWidth: '50rem' }} stripedRows>
                    <Column headerClassName="border-b" field="photo_url" header="" body={renderThumbnail}/>
                    <Column headerClassName="border-b" field="id" sortable header="ID" />
                    <Column headerClassName="border-b" field="name" sortable header="Product Name" />
                    <Column headerClassName="border-b" field="description" sortable header="Description" />
                    <Column headerClassName="border-b" field="price" sortable header="Price" />
                    <Column headerClassName="border-b" header="" body={renderBuyButton}  />
                    
                  </DataTable>
                </div>
              </div>

              {
                selectedProduct && (
                  <Dialog header={selectedProduct.name || ''} visible={viewProduct} position={'center'} className="w-[95%] lg:w-1/3" onHide={() => handleViewProductModalClose()} footer={viewProductFooter} draggable={false} resizable={false}>
                    <div className="w-full">
                      <div className="image-container" id={selectedProduct.photo_url ? 'not' : 'empty'}>
                        <Galleria id={selectedProduct.photo_url} value={selectedProduct.photo_url ? selectedProduct.photo_url?.split(',') : ['/empty_product.png']} activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}  numVisible={5}
                          item={itemTemplate} thumbnail={thumbnailTemplate} className="h-50" />
                      </div>
                      <div className="">
                      <p className="text-lg">Product Description: <span className="font-bold text-BrandBlue">{selectedProduct.description}</span></p>
                        <p className="text-lg">Needed points: <span className="font-bold text-BrandBlue">{Number(selectedProduct.price).toFixed(2)}</span></p>
                        <p className="text-lg">Your Available points: <span className="font-bold text-BrandBlue">{Number(withdrawablepoint).toFixed(2)}</span></p>
                        <p className="text-lg">Points after Purchase: <span className="font-bold text-red-500">{Number(Math.abs(withdrawablepoint) - selectedProduct.price).toFixed(2)}</span></p>
                        <div className="grid grid-cols-2 gap-4 mt-4">
                          <div className="col-span-1">
                            <label htmlFor="description">Qty</label>
                            <InputNumber pt={{root: {className: 'border rounded-full px-4 py-3'}}} inputClassName="ring-0 w-1/2 text-center" value={qtyToBuy} onValueChange={(e) =>setQtyToBuy(e.value)} showButtons buttonLayout="horizontal" step={1}
                              decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                              mode="decimal" min={1} 
                              // max={Math.floor(Number(withdrawablepoint).toFixed(2)/Number(selectedProduct.price).toFixed(2))} 
                              // onClick={(e) => confirmBuyItem(selectedProduct)}
                            />
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </Dialog>
                )
              }
              

              </>
            )
          }
          </TabPanel>
          <TabPanel
           pt={{
            headerAction: ({ parent }) => ({
              className: parent.state.activeIndex === 1 ? 'bg-BrandGreen text-white' : ''
            })
            }} key={'1-2'} header={'Purchases'}
          >

            <div className="py-4">
              <div className=""> 
              <div className="">
                  {
                    purchaseTab && (
                      <div className="h-[400px]">
                        Loading...
                      </div>
                    )
                  }

                  {
                    !purchaseTab && (
                      <DataTable ref={dt} header={header} className="bg-transparent" value={prodpurchased} size={'medium'} paginator rows={10} rowsPerPageOptions={[10, 50, 100]} tableStyle={{ minWidth: '50rem' }} stripedRows>
                
                        <Column headerClassName="border-b"  field="transaction_id" sortable header="ID" />
                        <Column headerClassName="border-b" field="purchasedby.name" sortable header="Purchased by" />
                        <Column headerClassName="border-b" field="product.description" sortable header="Product Name" />
                        <Column headerClassName="border-b" field="product.price"  sortable header="Unit Price" />
                        <Column headerClassName="border-b"  field="quantity" sortable header="Qty" />
                        <Column headerClassName="border-b"  body={getTotalAmount} sortable header="Total" />
                        <Column headerClassName="border-b"  body={getStatusValue} sortable header="Status" />
                  
                        {user.is_admin==1 &&<Column headerClassName="border-b" header="" body={renderButtons}/>}
                    </DataTable>
                    )
                  }
                </div>

                
              </div>
            </div>
          </TabPanel>
        </TabView>
          
          

        </div>
      </div>
    </> 
  )
}

export default Shop;