import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../../AuthContext';
import therest from '../../therest';
import { UserContext } from '../../UserContext';
import { Chip } from 'primereact/chip';
import { Toast } from "primereact/toast";
import { Link } from 'react-router-dom';
import { Password } from "primereact/password";
import { InputText } from 'primereact/inputtext';
import { LoadingContext } from '../../contexts/LoadingContext';

const Login = () => {
  const toast = useRef(null); 

  const { login, isLoggedIn, apiToken} = useContext(AuthContext); 
  const { updateUser, updateModules } = useContext(UserContext);
  const { setIsShowLoading } = useContext(LoadingContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (isLoggedIn && apiToken) {
      window.location.replace('/#/');
    }
  }, [isLoggedIn, apiToken]);


  const handleLogin = () => {
    setIsShowLoading(true);
    const loginData = {
      username: username,
      password: password,
    };
    therest.post('/login', loginData)
    .then(response => {
      if(response.status){
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Login Successful'});

        var t = setTimeout(() => {
          const apiToken = response.token;
          // console.log(response.user);
          login(apiToken);
          updateUser(response.user);
          updateModules(response.modules);
          clearTimeout(t);
        }, 1000)
        // Notification('Success', 'Login Successful', 'success');
      } else {
        // Notification('Login Failed!', response.message, 'danger');  
        toast.current.show({ severity: 'error', summary: 'Login failed', detail: response.message });
      }
      setIsShowLoading(false);
    })
    .catch(error => {
      console.error('Login failed:', error);
      toast.current.show({ severity: 'error', summary: 'Login failed', detail: error.message });
      // Notification('Login Failed!', 'Invalid Credentials', 'danger');
      setIsShowLoading(false);
    });
  };

  const handleUsernameOnchange = (e) => {
    setUsername(e.target.value);
  }

  const handlePasswordOnchange = (e) => {
    setPassword(e.target.value);
  }

  const handlePasswordKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
  <>
    <Toast ref={toast} />
    <div className='w-full flex justify-center items-center h-screen bg-BrandBlue lg:p-0 p-4'>
      <div className="lg:w-2/6 w-full lg:p-[40px] p-4 bg-white border border-gray-200 rounded-[16px] shadow">
        <div className="space-y-6">
          <div className='flex justify-center'>
            <img alt={''} src='Logo.png'/>
          </div>
          <div className='text-center'>
            <a href='https://www.plancareph.com' target='_blank'><Chip label="https://www.plancareph.com" /></a>
          </div>
          <div>
            <label labelfor="usernameOrPhone" className="block mb-2 ml-3 text-sm font-medium text-gray-900">Enter your UserID/Referral Code</label>
            <InputText type="email" className='w-full ring-0 border rounded-full px-4 py-3' value={username} onChange={handleUsernameOnchange} name="usernameOrPhone" id="usernameOrPhone" />
          </div>
          <div>
            <label labelfor="password" className="block mb-2 ml-3 text-sm font-medium text-gray-900">Enter password</label>
            <Password onKeyDown={handlePasswordKeyPress} inputClassName='w-full ring-0 border rounded-full px-4 py-3' id="password" name="password" className="w-full" value={password} onChange={handlePasswordOnchange} feedback={false} toggleMask />
          </div>
          <div className='text-right px-4 my-3'>
            <Link className='text-sm text-BrandBlue hover:text-blue-500' target='' to={'/forgot-password'}>Forgot Password ?</Link>
          </div>
          <button type="button" onClick={handleLogin} className="w-full text-white bg-BrandGreen hover:bg-BrandBlue focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-4 px-4 text-center">Login</button>
          <div className="text-center my-2">
            <Link type="button" className="w-full text-white bg-BrandBlue focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-4 px-4 text-center" target='' to={'/register'}>Join Now</Link>  
          </div>
          
          
        </div>
      </div>
    </div>
  </>
  );
};

export default Login;